import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { authUserSelector } from 'ducks/auth';
import { dataOrderListSelector } from 'ducks/data/order';
import OrdersList from 'components/ListPage/OrdersList';

const OrdersInfo: React.FC = () => {
	const user = useSelector(authUserSelector, shallowEqual);
	const list = useSelector(dataOrderListSelector, shallowEqual);

	if (list.length <= 0) {
		if (!user!.provider!.provider) return <div className="center">You are not yet approved</div>;
		// eslint-disable-next-line react/no-unescaped-entities
		return <div className="center">Please, click "Update" button</div>;
	}
	return <OrdersList list={list} />;
};

export default OrdersInfo;
