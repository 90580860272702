import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import OrderDto from 'dto/OrderDto';
import { CodeDto } from 'dto/CodeDto';

/**
 * Constants
 **/
export const moduleName = 'data/info';

export const CLEAR = `${moduleName}/CLEAR`;
export const FETCH_ORDER = `${moduleName}/FETCH_ORDER`;
export const FETCH_LIST = `${moduleName}/FETCH_LIST`;
export const SET_PROGRESS = `${moduleName}/SET_PROGRESS`;

export interface DataInfoState {
	order: OrderDto | null;
	list: CodeDto[];
	progress: boolean;
}

export const initialState: DataInfoState = {
	list: [],
	order: null,
	progress: false,
};

export interface DataInfoAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: CodeDto[];
		order?: OrderDto | null;
		progress?: boolean;
	};
}

/**
 * Reducer
 **/
export default (state = initialState, action: DataInfoAction) => {
	const { type, payload } = action;

	switch (type) {
		case CLEAR:
			return { ...initialState };
		case FETCH_ORDER:
			return { ...state, order: payload?.order || null };
		case FETCH_LIST:
			return { ...state, list: payload?.list || [] };
		case SET_PROGRESS:
			return { ...state, progress: payload?.progress || false };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const fetchOrder = (order: OrderDto) => ({
	type: FETCH_ORDER,
	payload: { order },
});

export const fetchList = (list: CodeDto[]) => ({
	type: FETCH_LIST,
	payload: { list },
});

export const setProgress = (progress: boolean) => ({
	type: SET_PROGRESS,
	payload: { progress },
});

export const get = (id: number) => (dispatch: AppDispatch) => {
	dispatch(setProgress(true));
	api.order.get(id).then(res1 => {
		if (res1.success && res1.data) {
			dispatch(fetchOrder(res1.data));
			api.verification.grouped(res1.data!.number || 0).then(res2 => {
				if (res2.success && res2.data) {
					dispatch(fetchList(res2.data));
				}
				dispatch(setProgress(false));
			});
		}
	});
};

export const getList = (orderNum: number, userId?: number) => (dispatch: AppDispatch) => {
	dispatch(setProgress(true));
	api.verification.grouped(orderNum, userId).then(res => {
		if (res.success && res.data) {
			dispatch(fetchList(res.data));
		}
		dispatch(setProgress(false));
	});
};

const dataSelector = (state: AppState) => state.data;
export const dataInfoSelector = createSelector(dataSelector, data => data.info);
export const dataInfoOrderSelector = createSelector(dataInfoSelector, info => info.order);
export const dataInfoListSelector = createSelector(dataInfoSelector, info => info.list);
export const dataInfoProgressSelector = createSelector(dataInfoSelector, info => info.progress);
