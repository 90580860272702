import React from 'react';
import { CodeDto } from 'dto/CodeDto';

export interface RemoveItemProps {
	item: CodeDto;
}

const RemoveItem: React.FC<RemoveItemProps> = ({ item }) => {
	const { codeNum, orderNum, color, size, total, count_checked } = item;
	return (
		<tr>
			<td>
				<span>{orderNum}</span>
			</td>
			<td>
				<span>{codeNum}</span>
			</td>
			<td>
				<span className={color || ''}>{color}</span>
			</td>
			<td>{size}</td>
			<td>{count_checked}</td>
			<td>{total}</td>
			<td></td>
			<td>
				<div className="styled-select styled-select_short js-styled-select">
					<select name="categorys">
						<option>Finished</option>
						<option value="Finished">Finished</option>
						<option value="Start">Start</option>
					</select>
				</div>
			</td>
		</tr>
	);
};

export default RemoveItem;
