import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Svg from 'common/Svg';
import AddTicket from 'shared/AddTicket/AddTicket';
import { editTicketisOpenSelector, setOpen } from 'ducks/edit/ticket';
import { dataTicketsListSelector, getMy } from 'ducks/data/tickets';
import HelpDeskItem from 'components/ProfilePage/HelpDesk/HelpDeskItem';

const HelpDesk: React.FC = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(editTicketisOpenSelector, shallowEqual);
	const list = useSelector(dataTicketsListSelector, shallowEqual);

	useEffect(() => {
		dispatch(getMy());
	}, []);

	const handleAdd = () => {
		dispatch(setOpen(!isOpen));
	};

	return (
		<>
			<div className="profile__main">
				<div className="profile-header">
					<h1 className="profile-header__title">HelpDesk</h1>
					<button className="btn btn--viol profile-header__add" onClick={handleAdd}>
						<span className="profile-header__add-icn">
							<Svg name="plus" />
						</span>
						<span>New ticket</span>
					</button>
				</div>
				<div className="list-table-sec">
					<div className="table-overlay">
						<table className="list-table list-table--center">
							<thead>
								<tr>
									<th>Ticket number</th>
									<th>Create date</th>
									<th>Attached</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{list.map(item => (
									<HelpDeskItem ticket={item} key={item.id} />
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<AddTicket />
		</>
	);
};

export default HelpDesk;
