import { createEffect, createStore } from 'effector';
import api from 'api/index';

export const createReport = createEffect<File, void>(async file => {
	await api.report
		.create(file)
		.then(res => {
			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				}),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Отчет.xlsx');
			document.body.appendChild(link);
			link.click();
		})
		.catch(err => {
			alert(err);
		});
});

export const $reportProgress = createStore<boolean>(false)
	.on(createReport, () => true)
	.on(createReport.finally, () => false);
