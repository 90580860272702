import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import preloader from './preloader.gif';
import Svg from 'common/Svg';
import { dataOrderProgressIdSelector, getPdf } from 'ducks/data/order';
import OrderDto from 'dto/OrderDto';

export interface OrderItemProps {
	order: OrderDto;
	userId?: number;
}

const OrderItem: React.FC<OrderItemProps> = ({ order, userId }) => {
	// const [downloaded, setDownloaded] = useState(false);
	const { id, number, quantity, price, isDownload, mark, currency, supplyNumber } = order;

	const dispatch = useDispatch();
	const progressId = useSelector(dataOrderProgressIdSelector, shallowEqual);

	const handlePdf = () => {
		if (progressId === null) {
			dispatch(getPdf(order));
			// setDownloaded(true);
		}
	};

	const renderControls = () => {
		if (userId) {
			return isDownload ? (
				<Link to={`/admin/codelist/${userId}/${number}`}>
					<Svg name="list" className="doc" />
				</Link>
			) : (
				<></>
			);
		} else if (progressId === id) {
			return <img className="pdf" src={preloader} />;
		} else if (isDownload) {
			return (
				<>
					<Link to={`/list/item/${id}`}>
						<Svg name="list" className="doc" />
					</Link>
				</>
			);
		}
		return <Svg onClick={handlePdf} className="pdf" name="download_empty" />;
	};

	return (
		<tr>
			<td>
				<span>{number}</span>
			</td>
			<td>
				<span>{currency}</span>
			</td>
			<td>
				<span>{supplyNumber}</span>
			</td>
			<td>
				<span>{quantity}</span>
			</td>
			<td>
				<span>{price}</span>
			</td>
			<td>
				<span>{mark === 1 ? 'YES' : 'NO'}</span>
			</td>
			<td>
				<span />
			</td>
			<td>
				<div className="list-table__column">{renderControls()}</div>
			</td>
		</tr>
	);
};

export default OrderItem;
