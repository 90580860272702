import React, { useRef, useState } from 'react';

import './styled-select.scss';
import useOnClickOutside from 'hooks/useOnClickOutside';

export type Option = string | { key: any; value: any };

export interface StyledSelectProps {
	className?: string;
	placeholder?: string;
	value: Option | null;
	children: Option[];
	onChange: (option: Option) => void;
	// eslint-disable-next-line no-undef
	renderItem?: (option: Option, index?: number) => JSX.Element;
}

const StyledSelect: React.FC<StyledSelectProps> = ({
	className = '',
	value,
	children,
	placeholder = '',
	onChange,
	renderItem,
}) => {
	const popupRef = useRef(null);
	const [isOpen, setOpen] = useState(false);

	useOnClickOutside(popupRef, () => setOpen(false));

	const isObject = (element: Option) => {
		// eslint-disable-next-line no-prototype-builtins
		return element && element.hasOwnProperty('key');
	};

	const handleSelect = (option: Option) => {
		setOpen(false);
		onChange(option);
	};

	const handleOpen = () => {
		if (children && children.length > 0) {
			setOpen(true);
		}
	};

	// eslint-disable-next-line no-undef
	const renderDefaultItem = (option: Option, index: number): JSX.Element => {
		if (renderItem) return renderItem(option, index);
		return <>{isObject(option) ? (option as { key: any; value: any }).value : option}</>;
	};

	return (
		<div className={`styled-select ${className}`}>
			<div
				className={`select-selected ${children && children.length > 1 && 'arrow'} ${
					isOpen && 'select-arrow-active'
				}`}
				onClick={handleOpen}
			>
				{!value ? placeholder : isObject(value) ? (value as { key: any; value: any }).value : value}
			</div>
			<div ref={popupRef} className={`select-items ${isOpen ? 'select-arrow-active' : 'select-hide'}`}>
				{children.map((option, index) => (
					<div
						key={`option_${index}`}
						onClick={() => handleSelect(option)}
						title={isObject(option) ? (option as { key: any; value: any }).value : option}
					>
						{renderDefaultItem(option, index)}
					</div>
				))}
			</div>
		</div>
	);
};

export default StyledSelect;
