import React from 'react';
import VerificationStatusWait from 'components/VerificationPage/VerificationFilter/VerificationStatus/VerificationStatusWait';
import VerificationStatusSuccess from 'components/VerificationPage/VerificationFilter/VerificationStatus/VerificationStatusSuccess';
import VerificationStatusWarning from 'components/VerificationPage/VerificationFilter/VerificationStatus/VerificationStatusWarning';
import VerificationStatusError from 'components/VerificationPage/VerificationFilter/VerificationStatus/VerificationStatusError';
import { VerificationStatusType } from 'ducks/edit/verification';
import VerificationStatusEan from 'components/VerificationPage/VerificationFilter/VerificationStatus/VerificationStatusEan';

export interface VerificationStatusProps {
	type: VerificationStatusType;
}

const VerificationStatus: React.FC<VerificationStatusProps> = ({ type }) => {
	switch (type) {
		case 'success':
			return <VerificationStatusSuccess />;
		case 'warning':
			return <VerificationStatusWarning />;
		case 'error':
			return <VerificationStatusError />;
		case 'ean':
			return <VerificationStatusEan />;
		case 'wait':
		default:
			return <VerificationStatusWait />;
	}
};

export default VerificationStatus;
