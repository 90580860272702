import React from 'react';
import { RemovedStatusType } from 'ducks/edit/removed';
import RemovedStatusSuccess from 'components/RemovePage/RemoveFilter/RemoveStatus/RemovedStatusSuccess';
import RemovedStatusWarning from 'components/RemovePage/RemoveFilter/RemoveStatus/RemovedStatusWarning';
import RemovedStatusError from 'components/RemovePage/RemoveFilter/RemoveStatus/RemovedStatusError';
import RemovedStatusEan from 'components/RemovePage/RemoveFilter/RemoveStatus/RemovedStatusEan';
import RemovedStatusWait from 'components/RemovePage/RemoveFilter/RemoveStatus/RemovedStatusWait';

export interface RemoveStatusProps {
	type: RemovedStatusType;
}

const RemoveStatus: React.FC<RemoveStatusProps> = ({ type }) => {
	switch (type) {
		case 'success':
			return <RemovedStatusSuccess />;
		case 'warning':
			return <RemovedStatusWarning />;
		case 'error':
			return <RemovedStatusError />;
		case 'ean':
			return <RemovedStatusEan />;
		case 'wait':
		default:
			return <RemovedStatusWait />;
	}
};

export default RemoveStatus;
