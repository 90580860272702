import { createSelector } from 'reselect';
import { AnyAction } from 'redux';
import { AppState } from '../../redux/reducer';

/**
 * Constants
 **/
export const moduleName = 'ui/prloaders';

export const SET_PRELOADER = `${moduleName}/SET_PRELOADER`;

export interface UiPreloadersState {
	isPreloader: boolean;
}

export interface UiPreloadersAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		preloader?: boolean;
	};
}

export const initialState: UiPreloadersState = {
	isPreloader: false,
};

/**
 * Reducer
 **/
export default (state: UiPreloadersState = initialState, action: UiPreloadersAction): UiPreloadersState => {
	const { type, payload } = action;

	switch (type) {
		case SET_PRELOADER:
			return { ...state, isPreloader: payload?.preloader || false };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const setPreloader = (preloader: boolean): UiPreloadersAction => ({
	type: SET_PRELOADER,
	payload: { preloader },
});

/**
 * Selectors
 **/
const uiSelector = (state: AppState) => state.ui;
export const uiPreloadersSelector = createSelector(uiSelector, ui => ui.preloaders);
export const uiPrloadersPreloaderSelector = createSelector(uiPreloadersSelector, preloaders => preloaders.isPreloader);
