import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { $reportOneLoading, $user, $userLoading, getReportOne, getUser } from 'models/user';
import { getName } from 'utils/userHelper';
import Preloader from 'common/Preloader/Preloader';
import StyledSelect from 'common/StyledSelect/StyledSelect';
import { $colors, $models, $sizes, getSizesColorsUser } from 'models/code';
import { dataInfoListSelector, dataInfoProgressSelector, getList } from 'ducks/data/info';
import DeliveryItem from 'components/DeliveryPage/DeliveryItem';
import Svg from 'common/Svg';

export const CodeList: React.FC = () => {
	const dispatch = useDispatch();
	const { userId, id } = useParams<{ id: string; userId: string }>();
	const [size, setSize] = useState<string | null>(null);
	const [color, setColor] = useState<string | null>(null);
	const [model, setModel] = useState<string | null>(null);
	const userLoading = useStore($userLoading);
	const user = useStore($user);
	const sizes = useStore($sizes);
	const colors = useStore($colors);
	const models = useStore($models);
	const list = useSelector(dataInfoListSelector, shallowEqual);
	const progress = useSelector(dataInfoProgressSelector, shallowEqual);
	const reportLoading = useStore($reportOneLoading);

	useEffect(() => {
		getUser(parseInt(userId));
	}, [userId]);

	useEffect(() => {
		getSizesColorsUser({ userId: parseInt(userId), orderNum: parseInt(id) });
		dispatch(getList(parseInt(id), parseInt(userId)));
	}, [id, userId]);

	if (userLoading || !user || progress) return <Preloader />;

	const handleChangeSize = (value: any) => {
		setSize(value === 'Размер' ? null : value);
	};

	const handleChangeColor = (value: any) => {
		setColor(value === 'Цвет' ? null : value);
	};

	const handleChangeModel = (value: any) => {
		setModel(value === 'Model' ? null : value);
	};

	const handleReportOne = () => {
		getReportOne({ id: parseInt(id), userId: parseInt(userId) });
	};

	let filteredList = list;
	if (size) {
		filteredList = filteredList.filter(item => item.size === size);
	}
	if (color) {
		filteredList = filteredList.filter(item => item.color === color);
	}

	return (
		<div className="code-list">
			<section className="search-sec">
				<div className="user-list__filter">
					<div className="user-list__filter__row">
						<div className="collumn">
							<div className="info">
								<div className="title">User:</div>
								<div className="value">{getName(user)}</div>
							</div>
						</div>
						<div className="collumn">
							<div className="info">
								<div className="title">Order:</div>
								<div className="value">{id}</div>
							</div>
						</div>
					</div>
					<div className="user-list__filter__row">
						<div className="collumn">
							<div className="info top">
								<StyledSelect value={size} onChange={handleChangeSize} placeholder="Размер">
									{['Размер', ...sizes]}
								</StyledSelect>
								<StyledSelect value={color} onChange={handleChangeColor} placeholder="Цвет">
									{['Цвет', ...colors]}
								</StyledSelect>
								<StyledSelect value={model} onChange={handleChangeModel} placeholder="Model">
									{['Model', ...models]}
								</StyledSelect>
							</div>
						</div>
						<div className="collumn">
							<div className="user-list__filter__buttons1">
								{reportLoading ? (
									<Preloader />
								) : (
									<a className="svg_left" title="Report" onClick={handleReportOne}>
										<Svg name={'report'} />
										Report
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="table-overlay">
				<table className="list-table">
					<thead>
						<tr>
							<th>Delivery Article</th>
							<th>Name (eng)</th>
							<th>Item ID (RecId)</th>
							<th>Color ID</th>
							<th>Size ID</th>
							<th>Quantity</th>
							<th>Gtin</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{filteredList.map(item => (
							<DeliveryItem item={item} key={item.id} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};
