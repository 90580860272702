import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { checkHash } from 'ducks/auth';

const ConfirmPage: React.FC = () => {
	const dispatch = useDispatch();
	const { id, hash } = useParams<{ id: string; hash: string }>();

	useEffect(() => {
		dispatch(checkHash(id, hash));
	}, [id, hash]);

	return <Redirect to="/auth" />;
};

export default ConfirmPage;
