import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import './sign.scss';
import { login, authErrorSelector, setError } from 'ducks/auth';
import Preloader from 'common/Preloader/Preloader';
import { uiPrloadersPreloaderSelector } from 'ducks/ui/preloaders';
import Svg from 'common/Svg';

const LoginPage = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		dispatch(setError(null));
	}, []);

	const error = useSelector(authErrorSelector, shallowEqual);
	const isPreloader = useSelector(uiPrloadersPreloaderSelector, shallowEqual);

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(login(email, password));
	};

	const handleButton = () => {
		dispatch(login(email, password));
	};

	return (
		<div className="sign">
			<div className="sign__wrap">
				<div className="sign__bar">
					<div className="sign__logo">
						<Svg name="familia" />
					</div>
					<h1 className="sign__title">Partnership </h1>
					<p className="sign__txt">Let`s get you an account and start work together</p>
				</div>
				<form onSubmit={handleSubmit} className="sign-form">
					{isPreloader ? (
						<Preloader />
					) : (
						<>
							<div className="sign-form__header">
								<h2 className="sign-form__title">Sign In</h2>
								<Link className="sign-form__header-link" to="/auth/register">
									Sign Up &#8594;
								</Link>
							</div>
							<input
								className={`sign-form__input ${email === '' ? 'value' : ''}`}
								type="email"
								name="email"
								placeholder="Email"
								value={email}
								onChange={handleChangeEmail}
							/>
							<input
								className={`sign-form__input ${password === '' ? 'value' : ''}`}
								type="password"
								name="password"
								placeholder="Password"
								value={password}
								onChange={handleChangePassword}
							/>

							<p className="sign-form__error">{error && <span>{error}</span>}</p>

							<button className="btn sign-form__submit" type="submit" onClick={handleButton}>
								Sign In
							</button>
							<p className="sign-form__sub">
								<Link to="/auth/recovery">Forgot your password? &#8594;</Link>.
							</p>
						</>
					)}
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
