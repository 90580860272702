import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { dataOrderProgressSelector, getOrders } from 'ducks/data/order';
import Preloader from 'common/Preloader/Preloader';
import OrdersSearch from 'components/ListPage/OrdersSearch';
import OrdersInfo from 'components/ListPage/OrdersInfo';

const ListPage = () => {
	const dispatch = useDispatch();
	const progress = useSelector(dataOrderProgressSelector, shallowEqual);

	useEffect(() => {
		dispatch(getOrders());
	}, []);

	if (progress) return <Preloader />;

	const handleSearch = () => {
		dispatch(getOrders());
	};

	return (
		<>
			<OrdersSearch onSearch={handleSearch} />
			<OrdersInfo />
		</>
	);
};

export default ListPage;
