import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { dataUsersFilterSelector, setFilter } from 'ducks/data/users';

export interface UsersSearchProps {
	onSearch: () => void;
}

const UsersSearch: React.FC<UsersSearchProps> = ({ onSearch }) => {
	const [waitIdle, setWaitIdle] = useState<any>(null);
	const dispatch = useDispatch();
	const filter = useSelector(dataUsersFilterSelector, shallowEqual);

	const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setFilter('query', event.target.value));
		if (waitIdle) {
			clearTimeout(waitIdle);
		}
		setWaitIdle(setTimeout(onSearch, 1000));
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSearch();
	};

	return (
		<section className="search-sec">
			<div className="search-sec__wrap">
				<form onSubmit={handleSubmit} className="search">
					<input
						type="search"
						className="search__input"
						placeholder="Поиск..."
						onChange={handleChangeQuery}
						value={filter.query || ''}
					/>
					{/*<button className="btn btn--viol search__submit" onClick={onSearch}>*/}
					{/*	<i className="search__submit-icn">*/}
					{/*		<Svg name="search" />*/}
					{/*	</i>*/}
					{/*	<span>Search</span>*/}
					{/*</button>*/}
				</form>
			</div>
		</section>
	);
};

export default UsersSearch;
