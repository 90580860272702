import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import './sign.scss';
import { recovery, authErrorSelector, setError } from 'ducks/auth';
import Preloader from 'common/Preloader/Preloader';
import { uiPrloadersPreloaderSelector } from 'ducks/ui/preloaders';
import Svg from 'common/Svg';

const RecoveryPage = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const error = useSelector(authErrorSelector, shallowEqual);

	useEffect(() => {
		dispatch(setError(null));
	}, []);

	const isPreloader = useSelector(uiPrloadersPreloaderSelector, shallowEqual);

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(recovery(email));
	};

	// const handleButton = () => {
	// 	dispatch(recovery(email));
	// };

	return (
		<div className="sign">
			<div className="sign__wrap">
				<div className="sign__bar">
					<div className="sign__logo">
						<Svg name="familia" />
					</div>
					<h1 className="sign__title">Partnership </h1>
					<p className="sign__txt">Let`s get you an account and start work together</p>
				</div>
				<form onSubmit={handleSubmit} className="sign-form">
					{isPreloader ? (
						<Preloader />
					) : (
						<>
							<Link to="/auth" className="sign-form__back">
								<i className="sign-form__back-icn">
									<Svg name="sign-back" />
								</i>
								<span>Sign In</span>
							</Link>
							<input
								className={`sign-form__input ${email === '' ? 'value' : ''}`}
								type="email"
								name="email"
								placeholder="Email"
								value={email}
								onChange={handleChangeEmail}
							/>

							<p className="sign-form__error">{error && <span>{error}</span>}</p>

							<button className="btn sign-form__submit" type="submit">
								Send
							</button>
						</>
					)}
				</form>
			</div>
		</div>
	);
};

export default RecoveryPage;
