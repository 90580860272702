import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import OrderDto from 'dto/OrderDto';
import { logout } from 'ducks/auth';

/**
 * Constants
 **/
export const moduleName = 'data/order';

export const FETCH = `${moduleName}/FETCH`;
export const SET_PROGRESS = `${moduleName}/SET_PROGRESS`;
export const SET_PROGRESS_ID = `${moduleName}/SET_PROGRESS_ID`;
export const SET_FILTER = `${moduleName}/SET_FILTER`;
export const SET_DONE = `${moduleName}/SET_DONE`;
export const SET_DOWNLOADED = `${moduleName}/SET_DOWNLOADED`;

export interface DataOrderFilter {
	query?: string;
}

export const initialFilter: DataOrderFilter = {
	query: '',
};

export interface DataOrderState {
	list: OrderDto[];
	progress: boolean;
	progressId: number | null;
	filter: DataOrderFilter;
}

export const initialState: DataOrderState = {
	list: [],
	progress: false,
	progressId: null,
	filter: initialFilter,
};

export interface DataOrderAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: OrderDto[];
		progress?: boolean;
		progressId?: number | null;
		key?: keyof DataOrderFilter;
		value?: any;
	};
}

/**
 * Reducer
 **/
export default (state = initialState, action: DataOrderAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		case SET_PROGRESS:
			return { ...state, progress: payload?.progress || false };
		case SET_PROGRESS_ID:
			return { ...state, progressId: payload?.progressId || null };
		case SET_FILTER:
			return { ...state, filter: { ...state.filter, [payload!.key!]: payload?.value } };
		case SET_DONE: {
			const list = [...state.list];
			list[list.findIndex(item => item.id === payload!.progressId)].isDownload = payload?.value || false;
			return { ...state, list: list };
		}
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const fetch = (list: OrderDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const setProgress = (progress: boolean) => ({
	type: SET_PROGRESS,
	payload: { progress },
});

export const setProgressId = (progressId: number | null) => ({
	type: SET_PROGRESS_ID,
	payload: { progressId },
});

export const setFilter = (key: keyof DataOrderFilter, value: any) => ({
	type: SET_FILTER,
	payload: { key, value },
});

export const setDone = (progressId: number | null, value: any) => ({
	type: SET_DONE,
	payload: { progressId, value },
});

export const getOrders = () => (dispatch: AppDispatch, getState: () => AppState) => {
	const filter = getState().data.order.filter;
	dispatch(setProgress(true));
	api.order.list(filter.query).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
		dispatch(setProgress(false));
	});
};

export const updateOrders = () => (dispatch: AppDispatch) => {
	dispatch(setProgress(true));
	api.order.update().then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
		dispatch(setProgress(false));
	});
};

export const getPdf = (order: OrderDto) => (dispatch: AppDispatch) => {
	dispatch(setProgressId(order.id!));
	api.order
		.pdf(order)
		.then(res => {
			if (res.success && res.data) {
				setTimeout(() => {
					waitParse(order, res.data!, dispatch);
				}, 1000);
			}
		})
		.catch(() => {
			alert('Произошла ошибка');
			dispatch(setDone(order.id!, false));
			dispatch(setProgressId(null));
		});
};

export const updatePdf = (order: OrderDto) => (dispatch: AppDispatch) => {
	dispatch(setProgressId(order.id!));
	api.order
		.pdfUpdate(order)
		.then(res => {
			if (res.success && res.data) {
				setTimeout(() => {
					waitParse(order, res.data!, dispatch);
				}, 1000);
			}
		})
		.catch(() => {
			alert('Произошла ошибка');
			dispatch(setDone(order.id!, false));
			dispatch(setProgressId(null));
		});
};

const waitParse = (order: OrderDto, promiseUid: string, dispatch: AppDispatch) => {
	api.promise.get(promiseUid).then(res => {
		if (!res) {
			setTimeout(() => {
				waitParse(order, promiseUid, dispatch);
			}, 1000);
		} else if (res.success && res.data) {
			// const link = document.createElement('a');
			// link.href = `/upload/${res.data}`;
			// link.setAttribute('download', order.number + '.pdf');
			// document.body.appendChild(link);
			// link.click();
			dispatch(setProgressId(null));
			dispatch(setDone(order.id!, true));
			dispatch(getOrders());
		} else if (res.error && res.error.code === 401) {
			dispatch(logout());
		} else if (res.error && res.error.code === 400) {
			setTimeout(() => {
				waitParse(order, promiseUid, dispatch);
			}, 1000);
		} else {
			// eslint-disable-next-line no-console
			console.error(res.error);
			alert('Произошла ошибка');
			dispatch(setDone(order.id!, false));
			dispatch(setProgressId(null));
		}
	});
};

const dataSelector = (state: AppState) => state.data;
export const dataOrderSelector = createSelector(dataSelector, data => data.order);
export const dataOrderListSelector = createSelector(dataOrderSelector, order => order.list);
export const dataOrderProgressSelector = createSelector(dataOrderSelector, order => order.progress);
export const dataOrderProgressIdSelector = createSelector(dataOrderSelector, order => order.progressId);
export const dataOrderFilterSelector = createSelector(dataOrderSelector, order => order.filter);
