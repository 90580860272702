import { combineReducers } from 'redux';

import preloaders, { UiPreloadersState, initialState as UiPreloadersInitialState } from './preloaders';

export interface UiState {
	preloaders: UiPreloadersState;
}
export const uiSelector = (state: { ui: UiState }): UiState => state.ui;

export const initialState: UiState = {
	preloaders: UiPreloadersInitialState,
};

export default combineReducers({
	preloaders,
});
