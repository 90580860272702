import { AnyAction, combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { ThunkAction } from 'redux-thunk';

import ui, { UiState } from 'ducks/ui';
import auth, { AuthState } from 'ducks/auth';
import data, { DataState } from 'ducks/data';
import edit, { EditState } from 'ducks/edit';

export interface AppState {
	ui: UiState;
	auth: AuthState;
	data: DataState;
	edit: EditState;
}

export type AppDispatch = (action: AnyAction | ThunkAction<void, AppState, null, AnyAction>) => void;

const createRootReducer = (history: History): Reducer =>
	combineReducers({
		router: connectRouter(history),
		ui,
		auth,
		data,
		edit,
	});

export default createRootReducer;
