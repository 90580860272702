import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import './auth-page.scss';
import { authIsAuthorizedSelector } from 'ducks/auth';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import SuccessPage from './SuccessPage';
import ConfirmPage from './ConfirmPage';
import RecoveryPage from './RecoveryPage';

const AuthPage = () => {
	const isAuthorized = useSelector(authIsAuthorizedSelector);

	if (isAuthorized) {
		return <Redirect to="/" />;
	}

	return (
		<Switch>
			<Route path="/auth" exact component={LoginPage} />
			<Route path="/auth/register" exact component={RegisterPage} />
			<Route path="/auth/recovery" exact component={RecoveryPage} />
			<Route path="/auth/success" exact component={SuccessPage} />
			<Route path="/auth/recovery/:id::hash" component={ConfirmPage} />
		</Switch>
	);
};

export default AuthPage;
