import React from 'react';

import { CodeDto } from 'dto/CodeDto';

export interface DeliveryItemProps {
	item: CodeDto;
}

const DeliveryItem: React.FC<DeliveryItemProps> = ({ item }) => {
	const { model, title, color, size, total, barcode, count_checked, codeTov, count_total } = item;

	return (
		<tr>
			<td>{model}</td>
			<td>{title}</td>
			<td>{codeTov}</td>
			<td>{color}</td>
			<td>{size}</td>
			<td>{total}</td>
			<td>{barcode}</td>
			<td
				className={
					'status ' +
					(count_checked === count_total && parseInt(String(count_total)) !== 0 ? 'green' : 'orange')
				}
			>
				{count_checked === count_total && parseInt(String(count_total)) !== 0 ? (
					`Verified`
				) : (
					<>
						Verifying
						<br />
						{count_checked}/{count_total}
					</>
				)}
			</td>
		</tr>
	);
};

export default DeliveryItem;
