import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { dataRemovedListSelector, fetch } from 'ducks/data/removed';
import RemoveItem from 'components/RemovePage/RemoveItem';

const RemoveList: React.FC = () => {
	const dispatch = useDispatch();
	const list = useSelector(dataRemovedListSelector, shallowEqual);

	useEffect(() => {
		dispatch(fetch([]));
	}, []);

	return (
		<div className="list-table-sec">
			<div className="table-overlay">
				<table className="list-table">
					<thead>
						<tr>
							<th>Order Number</th>
							<th>Item ID</th>
							<th>Color ID</th>
							<th>Size ID</th>
							<th>Verified</th>
							<th>Total</th>
							<th>Last Scan</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{list.map(item => (
							<RemoveItem key={item.id} item={item} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default RemoveList;
