import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Svg from 'common/Svg';
import { dataOrderListSelector, getOrders, setFilter } from 'ducks/data/order';

const OrderStat = () => {
	const dispatch = useDispatch();
	const list = useSelector(dataOrderListSelector, shallowEqual);

	useEffect(() => {
		dispatch(setFilter('query', null));
		dispatch(getOrders());
	}, []);

	return (
		<div className="profile-orders">
			<div className="profile-orders__wrap">
				<h3 className="profile-orders__title">Active Orders</h3>
				<div className="profile-orders__val">{list.length} link</div>
				<Link to="/list" className="btn btn--viol profile-orders__btn">
					<span>Go</span>
					<span className="profile-orders__btn-icn">
						<Svg name="arrow-right-small" />
					</span>
				</Link>
			</div>
		</div>
	);
};

export default OrderStat;
