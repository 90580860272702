import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import UserDto from 'dto/UserDto';
import ProviderDto from 'dto/ProviderDto';

/**
 * Constants
 **/
export const moduleName = 'data/users';

export const FETCH = `${moduleName}/FETCH`;
export const SET_FILTER = `${moduleName}/SET_FILTER`;

export interface DataUserFilter {
	query?: string;
}

export const initialFilter: DataUserFilter = {
	query: '',
};

export interface DataUsersState {
	list: UserDto[];
	filter: DataUserFilter;
}

export const initialState: DataUsersState = {
	list: [],
	filter: initialFilter,
};

export interface DataUsersAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: UserDto[];
		key?: keyof DataUserFilter;
		value?: any;
	};
}

/**
 * Reducer
 **/
export default (state = initialState, action: DataUsersAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		case SET_FILTER:
			return { ...state, filter: { ...state.filter, [payload!.key!]: payload?.value } };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const fetch = (list: UserDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const setFilter = (key: keyof DataUserFilter, value: any) => ({
	type: SET_FILTER,
	payload: { key, value },
});

export const getUsers = () => (dispatch: AppDispatch, getState: () => AppState) => {
	const filter = getState().data.users.filter;
	api.admin.users(filter.query).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
	});
};

export const setUserProvider = (id: number, provider: ProviderDto) => (dispatch: AppDispatch) => {
	api.admin.setProvider(id, provider).then(() => {
		dispatch(getUsers());
	});
};

export const setUserPr = (id: number, pr: string | null) => (dispatch: AppDispatch) => {
	api.admin.setPr(id, pr).then(() => {
		dispatch(getUsers());
	});
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const emptyUserData = (id: number) => (dispatch: AppDispatch) => {
	api.admin.empty(id).then(res => {
		if (res.success) {
			alert('Очищено');
		}
	});
};

export const setUserActive = (id: number) => (dispatch: AppDispatch) => {
	api.admin.setActive(id).then(() => {
		dispatch(getUsers());
	});
};

const dataSelector = (state: AppState) => state.data;
export const dataUsersSelector = createSelector(dataSelector, data => data.users);
export const dataUsersListSelector = createSelector(dataUsersSelector, users => users.list);
export const dataUsersFilterSelector = createSelector(dataUsersSelector, users => users.filter);
