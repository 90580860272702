import React, { useState, useRef, useEffect } from 'react';

import './drop-group.scss';
import useOnClickOutside from 'hooks/useOnClickOutside';

export interface StyledSuggestProps {
	className?: string;
	placeholder?: string;
	value: string | null;
	list: any[];
	onChange: (option: any, index: number) => void;
	onChangeText?: (text: string) => void;
	onDropDown: (text: string | null) => void;
	error?: string | null;
	// eslint-disable-next-line no-undef
	renderItem?: (option: any, index: number) => JSX.Element;
}

const StyledSuggest: React.FC<StyledSuggestProps> = ({
	className = '',
	value,
	list,
	placeholder = '',
	onDropDown,
	onChange,
	renderItem,
	error,
	onChangeText,
}) => {
	const popupRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [stateValue, setStateValue] = useState(value);

	useEffect(() => {
		setStateValue(value);
	}, [value]);

	useOnClickOutside(popupRef, () => {
		setIsOpen(false);
	});

	const handleFocus = () => {
		onDropDown(stateValue);
		setIsOpen(true);
	};

	const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setStateValue(event.target.value);
		if (onChangeText) {
			onChangeText(event.target.value);
		}
		onDropDown(event.target.value);
		setIsOpen(true);
	};

	const handleChange = (option: any, index: number): void => {
		onChange(option, index);
		setIsOpen(false);
	};

	return (
		<div className={`drop-group ${isOpen && 'drop-group--open'} ${className}`}>
			<input
				className={`input input--drop ${error && 'error'}`}
				placeholder={placeholder}
				value={stateValue || ''}
				onFocus={handleFocus}
				onChange={handleChangeText}
			/>
			{isOpen && (
				<ul ref={popupRef} className="drop-group__list">
					{list.map((option, index) => (
						<li
							className="drop-group__item"
							key={`option_${index}`}
							onClick={() => handleChange(option, index)}
						>
							{renderItem ? renderItem(option, index) : option}
						</li>
					))}
				</ul>
			)}
			{error && <div className="error">{error}</div>}
		</div>
	);
};

export default StyledSuggest;
