import React from 'react';
import Svg from 'common/Svg';

export interface CheckBoxProps {
	label?: string;
	checked?: boolean;
	onChange?: () => void;
	disabled?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({ disabled, checked, onChange, label }) => {
	const handleChange = () => {
		if (disabled) return false;
		if (onChange) onChange();
		return true;
	};

	return (
		<label className={`check ${disabled ? 'disabled' : ''}`}>
			<input disabled={disabled} type="checkbox" checked={checked} onChange={handleChange} />
			<span className="check__dop">
				<Svg name="box" />
			</span>
			<span className="check__txt">{label}</span>
		</label>
	);
};

export default CheckBox;
