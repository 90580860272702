import { createEffect, createStore } from 'effector';

import api from 'api/index';

const getSizes = createEffect<number, string[]>(async orderNum => {
	return api.code.sizes(orderNum).then(res => {
		if (res.success && res.data) return res.data;
		throw Error(res.error?.message);
	});
});

const getSizesUser = createEffect<{ orderNum: number; userId: number }, string[]>(async ({ orderNum, userId }) => {
	return api.code.sizes(orderNum, userId).then(res => {
		if (res.success && res.data) return res.data;
		throw Error(res.error?.message);
	});
});

const getColors = createEffect<number, string[]>(async orderNum => {
	return api.code.colors(orderNum).then(res => {
		if (res.success && res.data) return res.data;
		throw Error(res.error?.message);
	});
});

const getColorsUser = createEffect<{ orderNum: number; userId: number }, string[]>(async ({ orderNum, userId }) => {
	return api.code.colors(orderNum, userId).then(res => {
		if (res.success && res.data) return res.data;
		throw Error(res.error?.message);
	});
});

const getModels = createEffect<number, string[]>(async orderNum => {
	return api.code.models(orderNum).then(res => {
		if (res.success && res.data) return res.data;
		throw Error(res.error?.message);
	});
});

const getModelsUser = createEffect<{ orderNum: number; userId: number }, string[]>(async ({ orderNum, userId }) => {
	return api.code.models(orderNum, userId).then(res => {
		if (res.success && res.data) return res.data;
		throw Error(res.error?.message);
	});
});

export const getSizesColors = createEffect<number, void>(async orderNum => {
	await getSizes(orderNum);
	await getColors(orderNum);
	await getModels(orderNum);
});

export const getSizesColorsUser = createEffect<{ orderNum: number; userId: number }, void>(
	async ({ orderNum, userId }) => {
		await getSizesUser({ orderNum, userId });
		await getColorsUser({ orderNum, userId });
		await getModelsUser({ orderNum, userId });
	},
);

const checkPdf = createEffect<string, boolean>(async pid => {
	return api.promise.get(pid).then(res => {
		if (!res) {
			setTimeout(() => checkPdf(pid), 1000);
			return false;
		} else if (res.success && res.data) {
			const link = document.createElement('a');
			link.href = `/upload/${res.data}`;
			link.setAttribute('download', '');
			document.body.appendChild(link);
			link.click();
			return true;
		} else if (res.error && res.error.code === 400) {
			setTimeout(() => checkPdf(pid), 1000);
			return false;
		}
		alert('Произошла ошибка');
		return true;
	});
});

export const getPdf = createEffect<
	{ orderNum: number; color: string | null; size: string | null; model: string | null; type: 'one' | 'ten' },
	void
>(async ({ orderNum, color, size, model, type }) => {
	api.code.pdf(orderNum, color, size, model, type).then(res => {
		if (res.success && res.data) {
			setTimeout(() => {
				checkPdf(res.data!);
			}, 1000);
		} else {
			throw Error(res.error?.message);
		}
	});
});

const checkEmpty = createEffect<string, boolean>(async pid => {
	return api.promise.get(pid).then(res => {
		if (!res) {
			setTimeout(() => checkEmpty(pid), 1000);
			return false;
		} else if (res.success && res.data) {
			const link = document.createElement('a');
			link.href = `/upload/${res.data}`;
			link.setAttribute('download', '');
			document.body.appendChild(link);
			link.click();
			return true;
		} else if (res.error && res.error.code === 400) {
			setTimeout(() => checkEmpty(pid), 1000);
			return false;
		}
		alert('Произошла ошибка');
		return true;
	});
});

export const getEmpty = createEffect<
	{ orderNum: number; color: string | null; size: string | null; model: string | null; type: 'one' | 'ten' },
	void
>(async ({ orderNum, color, size, model, type }) => {
	api.code.empty(orderNum, color, size, model, type).then(res => {
		if (res.success && res.data) {
			setTimeout(() => {
				checkEmpty(res.data!);
			}, 1000);
		} else {
			throw Error(res.error?.message);
		}
	});
});

export const $loading = createStore<boolean>(false)
	.on(getSizesColors, () => true)
	.on(getSizesColors.finally, () => false)
	.on(getSizesColorsUser, () => true)
	.on(getSizesColorsUser.finally, () => false)
	.on(getPdf, () => true)
	.on(checkPdf.doneData, (__, data) => !data)
	.on(getEmpty, () => true)
	.on(checkEmpty.doneData, (__, data) => !data);

export const $sizes = createStore<string[]>([])
	.on(getSizes.doneData, (__, data) => data)
	.on(getSizesUser.doneData, (__, data) => data);

export const $colors = createStore<string[]>([])
	.on(getColors.doneData, (__, data) => data)
	.on(getColorsUser.doneData, (__, data) => data);

export const $models = createStore<string[]>([])
	.on(getModels.doneData, (__, data) => data)
	.on(getModelsUser.doneData, (__, data) => data);

export const getReport = createEffect<number, void>(async id => {
	await api.order
		.report(id)
		.then(response => {
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				}),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${id} Scan Report.xlsx`);
			document.body.appendChild(link);
			// dispatch(setLogisticProgress(false));
			link.click();
		})
		.catch(() => {
			// dispatch(setLogisticProgress(false));
		});
});

export const $reportLoading = createStore<number | null>(null)
	.on(getReport, (__, data) => data)
	.on(getReport.finally, () => null);
