import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import { getMy } from 'ducks/data/tickets';
import { getOrders } from 'ducks/data/order';

/**
 * Constants
 **/
export const moduleName = 'edit/ticket';

export const SET_OPEN = `${moduleName}/SET_OPEN`;
export const CLEAR = `${moduleName}/CLEAR`;
export const CLEAR_ERRORS = `${moduleName}/CLEAR_ERRORS`;
export const SET_TOPIC = `${moduleName}/SET_TOPIC`;
export const SET_ORDER_ID = `${moduleName}/SET_ORDER_ID`;
export const SET_MESSAGE = `${moduleName}/SET_MESSAGE`;
export const SET_FILE = `${moduleName}/SET_FILE`;
export const SET_ERROR = `${moduleName}/SET_ERROR`;

export interface EditTicketErrors {
	topic: null | string;
	message: null | string;
}

export const initialErrors: EditTicketErrors = {
	topic: null,
	message: null,
};

export interface EditTicketState {
	isOpen: boolean;
	topic: string;
	message: string;
	orderId: number | null;
	attach: File | null;
	errors: EditTicketErrors;
}

export const initialState: EditTicketState = {
	isOpen: false,
	topic: '',
	message: '',
	orderId: null,
	attach: null,
	errors: initialErrors,
};

export interface EditTicketAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		topic?: string;
		message?: string;
		attach?: File | null;
		key?: keyof EditTicketErrors;
		value?: null | string;
		orderId?: number | null;
		open?: boolean;
	};
}

export default (state = initialState, action: EditTicketAction) => {
	const { type, payload } = action;

	switch (type) {
		case CLEAR:
			return { ...initialState };
		case CLEAR_ERRORS:
			return { ...state, errors: initialErrors };
		case SET_OPEN:
			return { ...state, isOpen: payload?.open || false };
		case SET_ORDER_ID:
			return { ...state, orderId: payload?.orderId || null };
		case SET_TOPIC:
			return { ...state, topic: payload?.topic || '' };
		case SET_MESSAGE:
			return { ...state, message: payload?.message || '' };
		case SET_FILE:
			return { ...state, attach: payload?.attach || null };
		case SET_ERROR:
			return { ...state, errors: { ...state.errors, [payload!.key!]: payload!.value } };
		default:
			return { ...state };
	}
};

export const clear = () => ({
	type: CLEAR,
});

export const clearErrors = () => ({
	type: CLEAR_ERRORS,
});

export const setOpen = (open: boolean) => ({
	type: SET_OPEN,
	payload: { open },
});

export const setOrderId = (orderId: number | null) => ({
	type: SET_ORDER_ID,
	payload: { orderId },
});

export const setTopic = (topic: string) => ({
	type: SET_TOPIC,
	payload: { topic },
});

export const setMessage = (message: string) => ({
	type: SET_MESSAGE,
	payload: { message },
});

export const setAttach = (attach: File | null) => ({
	type: SET_FILE,
	payload: { attach },
});

export const setError = (key: keyof EditTicketErrors, value: string | null) => ({
	type: SET_ERROR,
	payload: { key, value },
});

export const create = () => (dispatch: AppDispatch, getState: () => AppState) => {
	dispatch(clearErrors());
	const { topic, message, attach, orderId } = getState().edit.ticket;
	let isError = false;
	if (!topic || topic.trim().length <= 0) {
		isError = true;
		dispatch(setError('topic', 'Поле не заполнено'));
	}
	if (!message || message.trim().length <= 0) {
		isError = true;
		dispatch(setError('message', 'Поле не заполнено'));
	}
	if (!isError) {
		api.helpdesk.create({ topic, message, orderId }, attach).then(() => {
			dispatch(clear());
			dispatch(setOpen(false));
			dispatch(getMy());
			dispatch(getOrders());
		});
	}
};

const editSelector = (state: AppState) => state.edit;
export const editTicketSelector = createSelector(editSelector, edit => edit.ticket);
export const editTicketTopicSelector = createSelector(editTicketSelector, ticket => ticket.topic);
export const editTicketMessageSelector = createSelector(editTicketSelector, ticket => ticket.message);
export const editTicketAttachSelector = createSelector(editTicketSelector, ticket => ticket.attach);
export const editTicketErrorsSelector = createSelector(editTicketSelector, ticket => ticket.errors);
export const editTicketisOpenSelector = createSelector(editTicketSelector, ticket => ticket.isOpen);
