import React from 'react';
import TicketDto, { TicketStatus } from 'dto/TicketDto';
import Svg from 'common/Svg';
import moment from 'moment';
import { urlBackend } from '../../../config';
import { allow, done } from 'ducks/data/tickets';
import { useDispatch } from 'react-redux';

export interface TicketItemProps {
	ticket: TicketDto;
}

const getStatus = (status: TicketStatus) => {
	switch (status) {
		case 'done':
			return { color: 'green', text: 'Solved' };
		case 'open':
			return { color: 'blue', text: 'Open' };
		case 'progress':
			return { color: 'orange', text: 'In Progress' };
	}
};

const TicketItem: React.FC<TicketItemProps> = ({ ticket }) => {
	const dispatch = useDispatch();
	const { id, topic, createdAt, attach, status, user, orderId } = ticket;
	const showStatus = getStatus(status!);

	const handleDone = () => {
		dispatch(done(id!));
	};

	const handleAllow = () => {
		dispatch(allow(id!));
	};

	const getActions = () => {
		if (status === 'open') {
			if (orderId)
				return (
					<button className="btn btn--viol row_btn" onClick={handleAllow}>
						Разрешить скачивание
					</button>
				);
			return (
				<button className="btn btn--viol row_btn" onClick={handleDone}>
					Выполнено
				</button>
			);
		}
		return <></>;
	};

	return (
		<tr>
			<td>{moment(createdAt).format('DD.MM.YYYY')}</td>
			<td>{user?.provider?.title}</td>
			<td>{user?.provider?.provider}</td>
			<td>{topic}</td>
			<td>
				{attach && attach.path && (
					<a href={`${urlBackend}/upload/${attach.path}`} className="doc">
						<Svg name="paper" />
					</a>
				)}
			</td>
			<td>
				<span className={showStatus.color}>{showStatus.text}</span>
			</td>
			<td>
				<>
					{/*<div className="styled-select styled-select_short js-styled-select">*/}
					{/*	<select name="categorys">*/}
					{/*		<option>Finished</option>*/}
					{/*		<option value="Finished">Finished</option>*/}
					{/*		<option value="Start">Start</option>*/}
					{/*	</select>*/}
					{/*</div>*/}
					{getActions()}
				</>
			</td>
		</tr>
	);
};

export default TicketItem;
