import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import { getRemoved } from 'ducks/data/removed';

/**
 * Constants
 **/
export const moduleName = 'edit/removed';

export const CLEAR = `${moduleName}/CLEAR`;
export const SET_CODE = `${moduleName}/SET_CODE`;
export const SET_STATUS = `${moduleName}/SET_STATUS`;

export type RemovedStatusType = 'wait' | 'success' | 'error' | 'warning' | 'ean';

export interface EditRemovedState {
	code: string;
	status: RemovedStatusType;
}

export const initialState: EditRemovedState = {
	code: '',
	status: 'wait',
};

export interface EditRemovedAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		code?: string;
		status?: RemovedStatusType;
	};
}

export default (state = initialState, action: EditRemovedAction) => {
	const { type, payload } = action;

	switch (type) {
		case CLEAR:
			return { ...initialState };
		case SET_CODE:
			return { ...state, code: payload?.code || '' };
		case SET_STATUS:
			return { ...state, status: payload?.status || 'wait' };
		default:
			return { ...state };
	}
};

export const clear = () => ({
	type: CLEAR,
});

export const setCode = (code: string) => ({
	type: SET_CODE,
	payload: { code },
});

export const setStatus = (status: RemovedStatusType) => ({
	type: SET_STATUS,
	payload: { status },
});

export const checkCode = (userId?: number) => (dispatch: AppDispatch, getState: () => AppState) => {
	const { code } = getState().edit.removed;
	dispatch(setStatus('wait'));
	if (code.length === 13) {
		dispatch(setStatus('ean'));
		return;
	}
	dispatch(getRemoved(code));
	api.verification.remove(code, userId).then(res => {
		if (res.success) {
			dispatch(setStatus('success'));
		} else if (res.error?.code === 404) {
			dispatch(setStatus('error'));
		} else if (res.error?.code === 405) {
			dispatch(setStatus('warning'));
		}
	});
};

const editSelector = (state: AppState) => state.edit;
export const editRemovedSelector = createSelector(editSelector, edit => edit.removed);
export const editRemovedCodeSelector = createSelector(editRemovedSelector, removed => removed.code);
export const editRemovedStatusSelector = createSelector(editRemovedSelector, removed => removed.status);
