import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import ProviderDto from 'dto/ProviderDto';
import { AppDispatch, AppState } from '../../redux/reducer';

/**
 * Constants
 **/
export const moduleName = 'data/providers';

export const FETCH = `${moduleName}/FETCH`;

export interface DataProvidersState {
	list: ProviderDto[];
}

export const initialState: DataProvidersState = {
	list: [],
};

export interface DataProvidersAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: ProviderDto[];
	};
}

/**
 * Reducer
 **/
export default (state = initialState, action: DataProvidersAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const fetch = (list: ProviderDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const findByProvider = (provider: string) => (dispatch: AppDispatch) =>
	api.admin.providers(provider).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
	});

const dataSelector = (state: AppState) => state.data;
export const dataProvidersSelector = createSelector(dataSelector, data => data.providers);
export const dataProvidersListSelector = createSelector(dataProvidersSelector, providers => providers.list);
