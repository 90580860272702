import React from 'react';
import { Link } from 'react-router-dom';

import './sign.scss';
import Svg from 'common/Svg';

const SuccessPage = () => {
	return (
		<div className="sign">
			<div className="sign__wrap">
				<div className="sign__bar">
					<div className="sign__logo">
						<Svg name="familia" />
					</div>
					<h1 className="sign__title">Partnership </h1>
					<p className="sign__txt">Let`s get you an account and start work together</p>
				</div>
				<form className="sign-form">
					<Link to="/auth" className="sign-form__back">
						<i className="sign-form__back-icn">
							<Svg name="sign-back" />
						</i>
						<span>Sign In</span>
					</Link>
					<p className="sign-form__txt">
						An email with instructions will be sent to the specified email address.
					</p>
				</form>
			</div>
		</div>
	);
};

export default SuccessPage;
