import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import TicketDto from 'dto/TicketDto';

/**
 * Constants
 **/
export const moduleName = 'data/tickets';

export const FETCH = `${moduleName}/FETCH`;

export interface DataTicketsState {
	list: TicketDto[];
}

export const initialState: DataTicketsState = {
	list: [],
};

export interface DataTicketsAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: TicketDto[];
	};
}

/**
 * Reducer
 **/
export default (state = initialState, action: DataTicketsAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const fetch = (list: TicketDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const getMy = () => (dispatch: AppDispatch) =>
	api.helpdesk.my().then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
	});

export const getAll = () => (dispatch: AppDispatch) =>
	api.helpdesk.all().then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
	});

export const done = (id: number) => (dispatch: AppDispatch) =>
	api.helpdesk.done(id).then(() => {
		dispatch(getAll());
	});

export const allow = (id: number) => (dispatch: AppDispatch) =>
	api.helpdesk.allow(id).then(() => {
		dispatch(getAll());
	});

const dataSelector = (state: AppState) => state.data;
export const dataTicketsSelector = createSelector(dataSelector, data => data.tickets);
export const dataTicketsListSelector = createSelector(dataTicketsSelector, tickets => tickets.list);
