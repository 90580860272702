import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import './sign.scss';
import { authErrorSelector, setError, register } from 'ducks/auth';
import Preloader from 'common/Preloader/Preloader';
import { uiPrloadersPreloaderSelector } from 'ducks/ui/preloaders';
import Svg from 'common/Svg';

const RegisterPage = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [errorEmail, setErrorEmail] = useState<null | string>(null);
	const [title, setTitle] = useState('');
	const [errorTitle, setErrorTitle] = useState<null | string>(null);
	const [password, setPassword] = useState('');
	const [errorPassword, setErrorPassword] = useState<null | string>(null);
	const [repeat, setRepeat] = useState('');
	const [errorRepeat, setErrorRepeat] = useState<null | string>(null);

	const error = useSelector(authErrorSelector, shallowEqual);
	const isPreloader = useSelector(uiPrloadersPreloaderSelector, shallowEqual);

	const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
		if (!event.target.value || event.target.value.trim().length <= 0) {
			setErrorTitle('Field is empty');
		} else {
			setErrorTitle(null);
		}
	};

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		if (!event.target.value || event.target.value.trim().length <= 0) {
			setErrorEmail('Field is empty');
		} else if (
			// eslint-disable-next-line
			!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
				event.target.value,
			)
		) {
			setErrorEmail('Field mast contain email');
		} else {
			setErrorEmail(null);
		}
	};

	const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		if (!event.target.value || event.target.value.trim().length <= 0) {
			setErrorPassword('Field is empty');
		} else {
			setErrorPassword(null);
		}
	};

	const handleChangeRepeat = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRepeat(event.target.value);
		if (!event.target.value || event.target.value.trim().length <= 0) {
			setErrorRepeat('Repeat password');
		} else if (event.target.value !== password) {
			setErrorRepeat('Password not equal');
		} else {
			setErrorRepeat(null);
		}
	};

	const handleRegister = () => {
		dispatch(setError(null));
		setErrorTitle(null);
		setErrorEmail(null);
		setErrorPassword(null);
		setErrorRepeat(null);
		let isError = false;
		if (!title || title.trim().length <= 0) {
			isError = true;
			setErrorTitle('Field is empty');
		}
		if (!email || email.trim().length <= 0) {
			isError = true;
			setErrorEmail('Field is empty');
		} else if (
			// eslint-disable-next-line
			!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
				email,
			)
		) {
			isError = true;
			setErrorEmail('Field mast contain email');
		}
		if (!password || password.trim().length <= 0) {
			isError = true;
			setErrorPassword('Field is empty');
		}
		if (!repeat || repeat.trim().length <= 0) {
			isError = true;
			setErrorRepeat('Repeat password');
		} else if (repeat !== password) {
			isError = true;
			setErrorRepeat('Password not equal');
		}
		if (!isError) {
			dispatch(register(title, email, password));
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		handleRegister();
	};

	const handleButton = () => {
		handleRegister();
	};

	return (
		<div className="sign">
			<div className="sign__wrap">
				<div className="sign__bar">
					<div className="sign__logo">
						<Svg name="familia" />
					</div>
					<h1 className="sign__title">Partnership </h1>
					<p className="sign__txt">Let`s get you an account and start work together</p>
				</div>
				<form onSubmit={handleSubmit} className="sign-form">
					{isPreloader ? (
						<Preloader />
					) : (
						<>
							<Link to="/auth" className="sign-form__back">
								<i className="sign-form__back-icn">
									<Svg name="sign-back" />
								</i>
								<span>Sign In</span>
							</Link>
							<input
								className={`sign-form__input ${
									errorTitle ? 'error' : title !== '' ? 'valid' : 'value'
								}`}
								type="text"
								name="name"
								placeholder="Supplier name"
								value={title}
								onChange={handleChangeTitle}
							/>
							{errorTitle && <div className="error">{errorTitle}</div>}
							<input
								className={`sign-form__input ${
									errorEmail ? 'error' : email !== '' ? 'valid' : 'value'
								}`}
								type="email"
								name="email"
								placeholder="Email"
								value={email}
								onChange={handleChangeEmail}
							/>
							{errorEmail && <div className="error">{errorEmail}</div>}
							<input
								className={`sign-form__input ${
									errorPassword ? 'error' : password !== '' ? 'valid' : 'value'
								}`}
								type="password"
								name="password"
								placeholder="Password"
								value={password}
								onChange={handleChangePassword}
							/>
							{errorPassword && <div className="error">{errorPassword}</div>}
							<input
								className={`sign-form__input ${
									errorRepeat ? 'error' : repeat !== '' && repeat === password ? 'valid' : 'value'
								}`}
								type="password"
								name="repeat_password"
								placeholder="Repeat password"
								value={repeat}
								onChange={handleChangeRepeat}
							/>
							{errorRepeat && <div className="error">{errorRepeat}</div>}
							<p className="sign-form__txt">
								By creating an accout, you agree to{' '}
								<a href="#" target="_blank">
									Privacy and Terms
								</a>
								.
							</p>

							<p className="sign-form__error">{error && <span>{error}</span>}</p>

							<button className="btn sign-form__submit" type="submit" onClick={handleButton}>
								Sign Up
							</button>
						</>
					)}
				</form>
			</div>
		</div>
	);
};

export default RegisterPage;
