import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './filter.scss';
import Svg from 'common/Svg';
import { dataInfoOrderSelector } from 'ducks/data/info';
import {
	$colors,
	$loading,
	$models,
	$reportLoading,
	$sizes,
	getEmpty,
	getPdf,
	getReport,
	getSizesColors,
} from 'models/code';
import StyledSelect from 'common/StyledSelect/StyledSelect';
import { useStore } from 'effector-react';
import Preloader from 'common/Preloader/Preloader';
import { updatePdf } from 'ducks/data/order';

export interface DeliveryFilterProps {
	orderNum: number;
	setSize: (val: string | null) => void;
	setColor: (val: string | null) => void;
	setModel: (val: string | null) => void;
	size: string | null;
	color: string | null;
	model: string | null;
}

const DeliveryFilter: React.FC<DeliveryFilterProps> = ({
	orderNum,
	setSize,
	setColor,
	setModel,
	color,
	size,
	model,
}) => {
	const dispatch = useDispatch();
	const order = useSelector(dataInfoOrderSelector, shallowEqual);
	const isLoading = useStore($loading);
	const sizes = useStore($sizes);
	const colors = useStore($colors);
	const models = useStore($models);
	const isReport = useStore($reportLoading);

	useEffect(() => {
		getSizesColors(orderNum);
	}, [orderNum]);

	const handleChangeSize = (value: any) => {
		setSize(value === 'Size' ? null : value);
	};

	const handleChangeColor = (value: any) => {
		setColor(value === 'Color' ? null : value);
	};

	const handleChangeModel = (value: any) => {
		setModel(value === 'Model' ? null : value);
	};

	const handleDownloadPdf = () => {
		getPdf({ orderNum: order!.number!, color, size, model, type: 'ten' });
	};

	const handleDownloadEmpty = () => {
		getEmpty({ orderNum: order!.number!, color, size, model, type: 'ten' });
	};

	const handleDownloadPdfOne = () => {
		getPdf({ orderNum: order!.number!, color, size, model, type: 'one' });
	};

	const handleDownloadEmptyOne = () => {
		getEmpty({ orderNum: order!.number!, color, size, model, type: 'one' });
	};

	const handleUpdate = () => {
		dispatch(updatePdf(order!));
	};

	const handleReport = () => {
		getReport(orderNum);
	};

	return (
		<section className="search-sec">
			<div className="search-sec__wrap filter">
				<div className="info__filter">
					<div className="filter__row">
						<div className="collumn">
							<div className="info">
								<div className="title">Order Number:</div>
								<div className="value">{order?.number}</div>
							</div>
							<div className="info top">
								<StyledSelect value={size} onChange={handleChangeSize} placeholder="Size">
									{['Size', ...sizes]}
								</StyledSelect>
								<StyledSelect value={color} onChange={handleChangeColor} placeholder="Color">
									{['Color', ...colors]}
								</StyledSelect>
								<StyledSelect value={model} onChange={handleChangeModel} placeholder="Model">
									{['Model', ...models]}
								</StyledSelect>
							</div>
						</div>
						{isLoading || order === null || isReport ? (
							<Preloader />
						) : (
							<>
								<div className="filter__buttons">
									{order.mark && order.mark !== 0 && (
										<>
											<a onClick={handleDownloadPdf} title="10">
												<Svg name={'print1'} />
												Mark
											</a>
											<a onClick={handleDownloadPdfOne} title="1">
												<Svg name={'print2'} />
												Print
											</a>
										</>
									)}
									<div className="split">---</div>
									<a className="svg_left" onClick={handleDownloadEmpty} title="10">
										<Svg name={'print3'} />
										No mark
									</a>
									<a onClick={handleDownloadEmptyOne} title="1">
										<Svg name={'print4'} />
										Print
									</a>
									<div className="split">---</div>
									<a className="svg_left" onClick={handleReport} title="Report">
										<Svg name={'report'} />
										Report
									</a>
								</div>
							</>
						)}
					</div>
					<button className="btn btn--viol search__submit search__update" onClick={handleUpdate}>
						<span>Update</span>
					</button>
				</div>
			</div>
		</section>
	);
};

export default DeliveryFilter;
