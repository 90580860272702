import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './sidebar.scss';
import Svg from 'common/Svg';
import { authUserSelector, logout } from 'ducks/auth';

const Sidebar: React.FC = () => {
	const dispatch = useDispatch();
	const user = useSelector(authUserSelector, shallowEqual);

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<div className="sidebar">
			<div className="sidebar__header">
				<a href="/" className="logo">
					<Svg name="home" />
				</a>
			</div>
			<nav className="sidebar-tools">
				<ul>
					{user?.isAdmin && (
						<li>
							<Link to="/admin/report" className="sidebar-tool">
								<Svg name="text-document" />
							</Link>
						</li>
					)}
					{user?.isAdmin && (
						<li>
							<Link to="/admin/users" className="sidebar-tool">
								<Svg name="cog" />
							</Link>
						</li>
					)}
					<li>
						<Link to="/auth" onClick={handleLogout} className="sidebar-tool">
							<Svg name="logout" />
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Sidebar;
