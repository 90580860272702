import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './add-blc.scss';
import Svg from 'common/Svg';
import {
	create,
	editTicketAttachSelector,
	editTicketErrorsSelector,
	editTicketisOpenSelector,
	editTicketMessageSelector,
	editTicketTopicSelector,
	setAttach,
	setMessage,
	setOpen,
	setTopic,
} from 'ducks/edit/ticket';

const AddTicket: React.FC = () => {
	const dispatch = useDispatch();
	const topic = useSelector(editTicketTopicSelector, shallowEqual);
	const message = useSelector(editTicketMessageSelector, shallowEqual);
	const attach = useSelector(editTicketAttachSelector, shallowEqual);
	const isOpen = useSelector(editTicketisOpenSelector, shallowEqual);
	const errors = useSelector(editTicketErrorsSelector, shallowEqual);

	const handleChangeTopic = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setTopic(event.target.value));
	};

	const handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		dispatch(setMessage(event.target.value));
	};

	const handleChangeAttach = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files?.length > 0) {
			dispatch(setAttach(event.target.files[0]));
		}
	};

	const handleOnOpen = () => {
		dispatch(setOpen(!isOpen));
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		handleSave();
	};

	const handleSave = () => {
		dispatch(create());
	};

	return (
		<div className={`add-blc ${isOpen ? 'open' : ''}`}>
			<button className="btn btn--viol add-blc__btn" onClick={handleOnOpen}>
				<Svg name="add" />
			</button>
			<div className="add-blc__wrap">
				<div className="add-blc__header">
					<h2 className="add-blc__title">Create new ticket</h2>
				</div>
				<form className="add-blc__form" onSubmit={handleSubmit}>
					<label className="add-blc__label">
						<span className="add-blc__label-title">Topic</span>
						<input
							type="text"
							className="add-blc__input"
							name="topic"
							placeholder="Topic"
							value={topic}
							onChange={handleChangeTopic}
						/>
						{errors.topic && <div className="error">{errors.topic}</div>}
					</label>
					<label className="add-blc__label">
						<span className="add-blc__label-title">Message</span>
						<textarea
							className="add-blc__input add-blc__textarea"
							name="message"
							placeholder="Enter the message..."
							value={message}
							onChange={handleChangeMessage}
						/>
						{errors.message && <div className="error">{errors.message}</div>}
					</label>
					<label className="add-blc__file">
						<input type="file" name="file" hidden onChange={handleChangeAttach} />
						<span className="add-blc__file-title">
							<span className="add-blc__file-title-icn">
								<Svg name="attach" />
							</span>
							<span>{attach && (attach as File).name ? (attach as File).name : 'Attach file'}</span>
						</span>
					</label>
					<button className="btn btn--viol add-blc__submit">
						<span className="profile-header__add-icn">
							<Svg name="plus" />
						</span>
						<span>Create ticket</span>
					</button>
				</form>
			</div>
		</div>
	);
};

export default AddTicket;
