import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import OrderDto from 'dto/OrderDto';
import { CodeDto } from 'dto/CodeDto';

/**
 * Constants
 **/
export const moduleName = 'data/verification';

export const FETCH = `${moduleName}/FETCH`;
export const SET_PROGRESS = `${moduleName}/SET_PROGRESS`;

export interface DataVerificationState {
	list: CodeDto[];
	progress: boolean;
}

export const initialState: DataVerificationState = {
	list: [],
	progress: false,
};

export interface DataVerificationAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		list?: CodeDto[];
		progress?: boolean;
	};
}

/**
 * Reducer
 **/
export default (state = initialState, action: DataVerificationAction) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH:
			return { ...state, list: payload?.list || [] };
		case SET_PROGRESS:
			return { ...state, progress: payload?.progress || false };
		default:
			return { ...state };
	}
};

/**
 * Action Creators
 **/
export const fetch = (list: OrderDto[]) => ({
	type: FETCH,
	payload: { list },
});

export const setProgress = (progress: boolean) => ({
	type: SET_PROGRESS,
	payload: { progress },
});

export const getVerifications = (matrix?: string) => (dispatch: AppDispatch) => {
	dispatch(setProgress(true));
	api.verification.list(matrix).then(res => {
		if (res.success && res.data) {
			dispatch(fetch(res.data));
		} else {
			dispatch(fetch([]));
		}
		dispatch(setProgress(false));
	});
};

const dataSelector = (state: AppState) => state.data;
export const dataVerificationSelector = createSelector(dataSelector, data => data.verification);
export const dataVerificationListSelector = createSelector(dataVerificationSelector, verification => verification.list);
export const dataVerificationProgressSelector = createSelector(
	dataVerificationSelector,
	verification => verification.progress,
);
