import { combineReducers } from 'redux';

import providers, { DataProvidersState, initialState as DataProvidersInitialState } from './providers';
import users, { DataUsersState, initialState as DataUsersInitialState } from './users';
import order, { DataOrderState, initialState as DataOrderInitialState } from './order';
import tickets, { DataTicketsState, initialState as DataTicketsInitialState } from './tickets';
import verification, { DataVerificationState, initialState as DataVerificationInitialState } from './verification';
import info, { DataInfoState, initialState as DataInfoInitialState } from './info';
import removed, { DataRemovedState, initialState as DataRemovedInitialState } from 'ducks/data/removed';

export interface DataState {
	providers: DataProvidersState;
	users: DataUsersState;
	order: DataOrderState;
	tickets: DataTicketsState;
	verification: DataVerificationState;
	info: DataInfoState;
	removed: DataRemovedState;
}
export const dataSelector = (state: { data: DataState }): DataState => state.data;

export const initialState: DataState = {
	providers: DataProvidersInitialState,
	users: DataUsersInitialState,
	order: DataOrderInitialState,
	tickets: DataTicketsInitialState,
	verification: DataVerificationInitialState,
	info: DataInfoInitialState,
	removed: DataRemovedInitialState,
};

export default combineReducers<DataState>({
	providers,
	users,
	order,
	tickets,
	verification,
	info,
	removed,
});
