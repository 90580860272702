import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';

import './user-list.scss';
import { $user, $userLoading, getUser } from 'models/user';
import Preloader from 'common/Preloader/Preloader';
import { getName } from 'utils/userHelper';
import { $list, $listLoading, getUserOrders } from 'models/userlist';
import OrderItem from 'components/ListPage/OrderItem';

export const UserList: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const userLoading = useStore($userLoading);
	const user = useStore($user);
	const listLoading = useStore($listLoading);
	const list = useStore($list);

	useEffect(() => {
		getUser(parseInt(id));
		getUserOrders(parseInt(id));
	}, [id]);

	if (userLoading || !user || listLoading) return <Preloader />;

	return (
		<div className="user-list">
			<section className="search-sec">
				<div className="user-list__filter">
					<div className="user-list__filter__row">
						<div className="collumn">
							<div className="info">
								<div className="title">User:</div>
								<div className="value">{getName(user)}</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="table-overlay">
				<table className="list-table">
					<thead>
						<tr>
							<th>Order Number</th>
							<th>Currency</th>
							<th>Delivery Number</th>
							<th>Quantity</th>
							<th>Total Sum </th>
							<th>Marking</th>
							<th>Comments</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{list.map(item => (
							<OrderItem userId={user?.id} order={item} key={item.id} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};
