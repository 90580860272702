import UserDto from 'dto/UserDto';
import noPhoto from './anonim.png';
import { urlBackend } from '../config';

export const getName = (user?: UserDto) => (user ? user.email : '');

export const getAvatar = (user: UserDto) =>
	user.avatar
		? typeof user.avatar === 'string' && (user.avatar as string).indexOf('base64') >= 0
			? user.avatar
			: `${urlBackend}/upload/${user.avatar.path}`
		: noPhoto;
