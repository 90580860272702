import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './profile.scss';
import Svg from 'common/Svg';
import HelpDesk from 'components/ProfilePage/HelpDesk/HelpDesk';
import OrderStat from 'components/ProfilePage/OrderStat/OrderStat';
import { authUserSelector, update, updateAvatar } from 'ducks/auth';
import { getAvatar, getName } from 'utils/userHelper';

const ProfilePage: React.FC = () => {
	const dispatch = useDispatch();
	const user = useSelector(authUserSelector, shallowEqual);

	useEffect(() => {
		dispatch(update());
	}, []);

	const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.files && event.target.files?.length > 0) {
			const reader = new FileReader();

			reader.onloadend = () => {
				dispatch(updateAvatar(reader.result as string, event.target.files![0]));
			};

			reader.readAsDataURL(event.target.files[0]);
		}
	};

	return (
		<div className="profile">
			<div className="profile__bar">
				<div className="profile-ava">
					<div className="profile-ava__wrap">
						<div className="profile-ava__pic">
							<img src={getAvatar(user!)} alt={getName(user!)} />
						</div>
						<label className="btn btn--viol profile-ava__btn">
							<input type="file" onChange={handleChangeAvatar} accept=".jpg,.jpeg,.png" />
							<Svg name="add" />
						</label>
					</div>
				</div>
				<div className="profile-desc">
					<div className="profile-desc__row">
						<div className="profile-desc__icn">
							<Svg name="user" />
						</div>
						<div className="profile-desc__main">
							<div className="profile-desc__val">{user!.title}</div>
							<div className="profile-desc__name">Partner name</div>
						</div>
					</div>
					<div className="profile-desc__row">
						<div className="profile-desc__icn">
							<Svg name="envelope" />
						</div>
						<div className="profile-desc__main">
							<div className="profile-desc__val">{user!.email}</div>
							<div className="profile-desc__name">Emal</div>
						</div>
					</div>
					<div className="profile-desc__row">
						<div className="profile-desc__icn">
							<Svg name="id" />
						</div>
						<div className="profile-desc__main">
							<div className="profile-desc__val">{user?.provider?.provider || ''}</div>
							<div className="profile-desc__name">Contract number</div>
						</div>
					</div>
					<div className="profile-desc__row">
						<div className="profile-desc__icn">
							<Svg name="globe-americas" />
						</div>
						<div className="profile-desc__main">
							<div className="profile-desc__val">Russia</div>
							<div className="profile-desc__name">Country</div>
						</div>
					</div>
				</div>
				<OrderStat />
			</div>
			<HelpDesk />
		</div>
	);
};

export default ProfilePage;
