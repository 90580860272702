import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { dataTicketsListSelector, getAll } from 'ducks/data/tickets';
import TicketItem from 'components/AdminPage/TicketsPage/TicketItem';

const TicketsPage: React.FC = () => {
	const dispatch = useDispatch();
	const list = useSelector(dataTicketsListSelector, shallowEqual);

	useEffect(() => {
		dispatch(getAll());
	}, []);

	return (
		<div className="list-table-sec">
			<div className="table-overlay">
				<table className="list-table list-table--center">
					<thead>
						<tr>
							<th>Дата</th>
							<th>Поставщик</th>
							<th>Пэшка</th>
							<th>Вопрос</th>
							<th>Файл</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{list.map(item => (
							<TicketItem ticket={item} key={item.id} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TicketsPage;
