import { createEffect, createStore } from 'effector';
import UserDto from 'dto/UserDto';
import api from 'api/index';

export const getUser = createEffect<number, UserDto>(async id => {
	return await api.admin.user(id).then(res => {
		if (res.success && res.data) return res.data as UserDto;
		throw Error(res.error?.message);
	});
});

export const $userLoading = createStore<boolean>(false)
	.on(getUser, () => true)
	.on(getUser.finally, () => false);

export const getReport = createEffect<number, void>(async id => {
	await api.admin
		.report(id)
		.then(response => {
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				}),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${id} Scan Report.xlsx`);
			document.body.appendChild(link);
			// dispatch(setLogisticProgress(false));
			link.click();
		})
		.catch(() => {
			// dispatch(setLogisticProgress(false));
		});
});

export const getReportOne = createEffect<{ id: number; userId: number }, void>(async ({ id, userId }) => {
	await api.admin
		.reportone(id, userId)
		.then(response => {
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				}),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${id} Scan Report.xlsx`);
			document.body.appendChild(link);
			// dispatch(setLogisticProgress(false));
			link.click();
		})
		.catch(() => {
			// dispatch(setLogisticProgress(false));
		});
});

export const $user = createStore<UserDto | null>(null).on(getUser.doneData, (__, data) => data);
export const $reportLoading = createStore<number | null>(null)
	.on(getReport, (__, data) => data)
	.on(getReport.finally, () => null);

export const $reportOneLoading = createStore<boolean>(false)
	.on(getReportOne, () => true)
	.on(getReportOne.finally, () => false);
