import React from 'react';
import Preloader from 'common/Preloader/Preloader';
import { useStore } from 'effector-react';
import { $reportProgress, createReport } from 'models/report';

import './report-page.scss';

export const ReportPage: React.FC = () => {
	const progress = useStore($reportProgress);

	const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			createReport(event.target.files[0]);
		}
	};

	return (
		<div className="report-page">
			{progress ? (
				<Preloader />
			) : (
				<label className="btn btn--viol row_btn">
					<input type="file" onChange={handleChangeFile} /> Загрузить
				</label>
			)}
		</div>
	);
};
