import axios, { AxiosResponse } from 'axios';

import flatten from 'utils/objectToFormData';
import { APIResponse, ResponseAuth } from '../types/ResponseType';
import UserDto from 'dto/UserDto';
import ProviderDto from 'dto/ProviderDto';
import OrderDto from 'dto/OrderDto';
import TicketDto from 'dto/TicketDto';
import { CodeDto } from 'dto/CodeDto';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendData = (res: AxiosResponse): APIResponse<any> => res.data;

export default {
	auth: {
		login: (email: string, password: string): Promise<APIResponse<ResponseAuth>> =>
			axios.post('/auth', { email, password }).then(sendData),
		register: (title: string, email: string, password: string): Promise<APIResponse<any>> =>
			axios.post('/auth/register', { title, email, password }).then(sendData),
		recovery: (email: string): Promise<APIResponse<any>> => axios.post('/auth/recovery', { email }).then(sendData),
		check: (id: string, hash: string): Promise<APIResponse<ResponseAuth>> =>
			axios.post(`/auth/check/${id}`, { hash }).then(sendData),
		update: (): Promise<APIResponse<UserDto>> => axios.get('/profile/update').then(sendData),
	},
	promise: {
		get: (uid: string): Promise<APIResponse<any>> => axios.get(`/promise/${uid}`).then(sendData),
	},
	admin: {
		users: (query?: string): Promise<APIResponse<UserDto[]>> =>
			axios.get(`/admin/provider/users?query=${query}`).then(sendData),
		user: (id: number): Promise<APIResponse<UserDto>> => axios.get(`/admin/provider/user/${id}`).then(sendData),
		setProvider: (id: number, provider: ProviderDto): Promise<APIResponse<any>> =>
			axios.post(`/admin/provider/users/${id}`, { provider }).then(sendData),
		setPr: (id: number, pr: string | null): Promise<APIResponse<any>> =>
			axios.post(`/admin/provider/pr/${id}`, { pr }).then(sendData),
		providers: (provider: string): Promise<APIResponse<ProviderDto[]>> =>
			axios.get(`/admin/provider?provider=${provider}`).then(sendData),
		orders: (userId: number): Promise<APIResponse<OrderDto[]>> =>
			axios.get(`/admin/order/${userId}`).then(sendData),
		empty: (userId: number): Promise<APIResponse<any>> => axios.delete(`/admin/order/${userId}`).then(sendData),
		setActive: (userId: number): Promise<APIResponse<any>> =>
			axios.put(`/admin/provider/user/${userId}`).then(sendData),
		report: (userId: number) =>
			axios({
				url: `/admin/order/report/${userId}`,
				method: 'GET',
				responseType: 'blob',
			}),
		reportone: (id: number, userId: number) =>
			axios({
				url: `/admin/order/reportone/${userId}/${id}`,
				method: 'GET',
				responseType: 'blob',
			}),
	},
	order: {
		get: (id: number): Promise<APIResponse<OrderDto>> => axios.get(`/order/${id}`).then(sendData),
		update: (): Promise<APIResponse<OrderDto[]>> => axios.get('/order/update').then(sendData),
		list: (query?: string): Promise<APIResponse<OrderDto[]>> => axios.get(`/order?query=${query}`).then(sendData),
		// pdf: (order: OrderDto) =>
		// 	axios({ url: '/order/pdf', responseType: 'blob', method: 'post', data: { order } }).then(res => res.data),
		pdf: (order: OrderDto): Promise<APIResponse<string>> => axios.post('/order/pdf', { order }).then(sendData),
		pdfUpdate: (order: OrderDto): Promise<APIResponse<string>> => axios.put('/order/pdf', { order }).then(sendData),
		report: (id: number) =>
			axios({
				url: `/order/report/${id}`,
				method: 'GET',
				responseType: 'blob',
			}),
	},
	profile: {
		avatar: (avatar: File) => axios.post('/profile/avatar', flatten({ avatar })).then(sendData),
	},
	helpdesk: {
		all: (): Promise<APIResponse<TicketDto[]>> => axios.get('/admin/ticket').then(sendData),
		my: (): Promise<APIResponse<TicketDto[]>> => axios.get('/ticket/my').then(sendData),
		create: (
			ticket: { topic: string; message: string; orderId: number | null },
			attach?: File | null,
		): Promise<APIResponse<TicketDto>> =>
			axios.post('/ticket/create', flatten({ attach, ticket: JSON.stringify(ticket) })).then(sendData),
		done: (id: number): Promise<APIResponse<any>> => axios.post(`/admin/ticket/done/${id}`).then(sendData),
		allow: (id: number): Promise<APIResponse<any>> => axios.post(`/admin/ticket/allow/${id}`).then(sendData),
	},
	verification: {
		grouped: (orderNum: number, userId?: number): Promise<APIResponse<CodeDto[]>> =>
			axios.get(`/verification/${orderNum}${userId ? '?userId=' + userId : ''}`).then(sendData),
		list: (matrix?: string): Promise<APIResponse<CodeDto[]>> =>
			axios.post('/verification', { matrix }).then(sendData),
		removed: (matrix?: string): Promise<APIResponse<CodeDto[]>> =>
			axios.post('/verification/removed', { matrix }).then(sendData),
		check: (matrix: string, userId?: number): Promise<APIResponse<any>> =>
			axios.post(`/verification/check${userId ? '?userId=' + userId : ''}`, { matrix }).then(sendData),
		remove: (matrix: string, userId?: number): Promise<APIResponse<any>> =>
			axios.post(`/verification/remove${userId ? '?userId=' + userId : ''}`, { matrix }).then(sendData),
	},
	code: {
		sizes: (orderNum: number, userId?: number): Promise<APIResponse<string[]>> =>
			axios.get(`/code/size/${orderNum}${userId ? '?userId=' + userId : ''}`).then(sendData),
		colors: (orderNum: number, userId?: number): Promise<APIResponse<string[]>> =>
			axios.get(`/code/color/${orderNum}${userId ? '?userId=' + userId : ''}`).then(sendData),
		models: (orderNum: number, userId?: number): Promise<APIResponse<string[]>> =>
			axios.get(`/code/model/${orderNum}${userId ? '?userId=' + userId : ''}`).then(sendData),
		pdf: (
			orderNum: number,
			color: string | null,
			size: string | null,
			model: string | null,
			type: 'one' | 'ten',
		): Promise<APIResponse<string>> =>
			axios.get(`/code/pdf/${orderNum}?size=${size}&color=${color}&model=${model}&type=${type}`).then(sendData),
		empty: (
			orderNum: number,
			color: string | null,
			size: string | null,
			model: string | null,
			type: 'one' | 'ten',
		): Promise<APIResponse<string>> =>
			axios.get(`/code/empty/${orderNum}?size=${size}&color=${color}&model=${model}&type=${type}`).then(sendData),
	},
	report: {
		create: (file: File) =>
			axios({
				url: `/report`,
				method: 'POST',
				data: flatten({ file }),
				responseType: 'blob',
			}),
	},
};
