import React from 'react';
import Svg from 'common/Svg';

const RemovedStatusError: React.FC = () => {
	return (
		<div className="alert alert--error">
			<div className="alert__row">
				<div className="alert__icn">
					<Svg name="tick-circle" />
				</div>
				<div className="alert__icn--big">
					<Svg name="x-circle-strong" />
				</div>
				<div className="alert__icn">
					<Svg name="tick-circle" />
				</div>
			</div>
			<div className="alert__txt">Code not found</div>
		</div>
	);
};

export default RemovedStatusError;
