import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Svg from 'common/Svg';
import VerificationStatus from 'components/VerificationPage/VerificationFilter/VerificationStatus/VerificationStatus';
import {
	checkCode,
	// editVerificationCodeSelector,
	editVerificationStatusSelector,
	setCode,
	setStatus,
} from 'ducks/edit/verification';
import { fetch } from 'ducks/data/verification';

const VerificationFilter: React.FC = () => {
	const dispatch = useDispatch();
	const [tmp, setTmp] = useState('');
	// const code = useSelector(editVerificationCodeSelector, shallowEqual);
	const status = useSelector(editVerificationStatusSelector, shallowEqual);

	const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		// dispatch(setCode(event.target.value));
		setTmp(event.target.value);
		if (event.target.value.length <= 0) {
			dispatch(setStatus('wait'));
		}
	};

	const handleCodeKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.code === 'Enter' && tmp.length > 0) {
			dispatch(setCode(tmp));
			dispatch(checkCode());
			setTmp('');
		} else if (event.code === 'Enter') {
			dispatch(fetch([]));
		}
	};

	return (
		<div className="main-sec__wrap">
			<div className="barcode">
				<h2 className="barcode__title">
					<div className="barcode__title-code">
						<Svg name="barcode" />
					</div>
					<div className="barcode__title-txt">Scan result</div>
				</h2>
				<input
					autoFocus
					type="text"
					className="barcode__val"
					value={tmp}
					onChange={handleChangeCode}
					onKeyPress={handleCodeKeyPress}
				/>
			</div>
			<VerificationStatus type={status} />
		</div>
	);
};

export default VerificationFilter;
