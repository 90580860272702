import React from 'react';
import Svg from 'common/Svg';

const VerificationStatusEan: React.FC = () => {
	return (
		<div className="alert alert--warning">
			<div className="alert__row">
				<div className="alert__icn">
					<Svg name="tick-circle" />
				</div>
				<div className="alert__icn--big">
					<Svg name="exclamation-circle-strong" />
				</div>
				<div className="alert__icn">
					<Svg name="x-circle" />
				</div>
			</div>
			<div className="alert__txt">Need to scan the DataMatrix code</div>
		</div>
	);
};

export default VerificationStatusEan;
