import React from 'react';

import './main-sec.scss';
import VerificationList from 'components/VerificationPage/VerificationList';
import VerificationFilter from 'components/VerificationPage/VerificationFilter/VerificationFilter';

const VerificationPage: React.FC = () => {
	return (
		<>
			<section className="main-sec">
				<VerificationFilter />
			</section>
			<VerificationList />
		</>
	);
};

export default VerificationPage;
