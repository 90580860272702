import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import UserDto from 'dto/UserDto';
import { dataProvidersListSelector, findByProvider } from 'ducks/data/providers';
import StyledSuggest from 'common/StyledSuggest/StyledSuggest';
import ProviderDto from 'dto/ProviderDto';
import { emptyUserData, setUserActive, setUserPr, setUserProvider } from 'ducks/data/users';
import Svg from 'common/Svg';
import { useStore } from 'effector-react';
import { $reportLoading, getReport } from 'models/user';
import preloader from 'components/ListPage/preloader.gif';
import CheckBox from 'common/CheckBox/CheckBox';

export interface UserItemProps {
	user: UserDto;
}

const UserItem: React.FC<UserItemProps> = ({ user }) => {
	const dispatch = useDispatch();
	const providersList = useSelector(dataProvidersListSelector, shallowEqual);
	const [prsList, setPrsList] = useState<string[]>(['Не задано']);
	const loading = useStore($reportLoading);

	const { id, email, title, provider, pr, active } = user;

	const handleChangeProviderText = (text: string | null) => {
		dispatch(findByProvider(text || ''));
	};

	const handleProviderDropDown = (text: string | null) => {
		dispatch(findByProvider(text || ''));
	};

	const handleChangeProvider = (option: ProviderDto) => {
		dispatch(setUserProvider(id!, option));
	};

	const handleChangePrText = (text: string | null) => {
		setPrsList(
			provider && provider.prs
				? provider.prs.filter(item => item.trim().indexOf((text || '000').trim()) >= 0)
				: [],
		);
	};

	const handlePrDropDown = (text: string | null) => {
		setPrsList([
			'Не задано',
			...(provider && provider.prs
				? provider.prs.filter(item => item.trim().indexOf((text || '').trim()) >= 0)
				: []),
		]);
	};

	const handleChangePr = (val: string) => {
		dispatch(setUserPr(id!, val === 'Не задано' ? null : val));
	};

	const handleClear = () => {
		if (confirm('Очистить данные пользователя?')) {
			dispatch(emptyUserData(user.id!));
		}
	};

	const handleReport = () => {
		getReport(id!);
	};

	const handleChangeActive = () => {
		if (confirm('Активировать пользователя?')) {
			dispatch(setUserActive(id!));
		}
	};

	return (
		<tr>
			<td>
				<span>{id}</span>
			</td>
			<td>
				{active ? (
					<Svg className="user-page__active" name="check" />
				) : (
					<CheckBox onChange={handleChangeActive} />
				)}
			</td>
			<td>
				<span>{email}</span>
			</td>
			<td>
				<span>{title}</span>
			</td>
			<td style={{ minWidth: '200px' }}>
				<StyledSuggest
					onChange={handleChangeProvider}
					value={provider && provider.provider ? provider.provider : null}
					list={providersList}
					placeholder="Номер пос."
					onChangeText={handleChangeProviderText}
					onDropDown={handleProviderDropDown}
					renderItem={option => <div className="drop-group__item-name">{option.provider}</div>}
				/>
			</td>
			<td style={{ minWidth: '200px' }}>
				<StyledSuggest
					onChange={handleChangePr}
					value={pr || null}
					list={prsList}
					placeholder="ПР"
					onChangeText={handleChangePrText}
					onDropDown={handlePrDropDown}
				/>
			</td>
			<td>{provider && provider.title ? provider.title : ''}</td>
			<td>
				<div className="buttons">
					<Link to={`/admin/userlist/${id}`} className="button">
						<Svg name="text-document" />
					</Link>
					<Svg className="icon_button" name="clear" onClick={handleClear} />
					{loading === id ? (
						<img className="pdf" src={preloader} />
					) : (
						<Svg className="icon_button" name="print4" onClick={handleReport} />
					)}
				</div>
			</td>
		</tr>
	);
};

export default UserItem;
