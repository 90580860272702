import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import DeliveryFilter from 'components/DeliveryPage/DeliveryFilter';
import DeliveryList from 'components/DeliveryPage/DeliveryList';
import { dataInfoOrderSelector, get } from 'ducks/data/info';
import Preloader from 'common/Preloader/Preloader';
import { dataOrderProgressIdSelector } from 'ducks/data/order';

const DeliveryPage: React.FC = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const [size, setSize] = useState<string | null>(null);
	const [color, setColor] = useState<string | null>(null);
	const [model, setModel] = useState<string | null>(null);
	const order = useSelector(dataInfoOrderSelector, shallowEqual);
	const progressId = useSelector(dataOrderProgressIdSelector, shallowEqual);

	useEffect(() => {
		dispatch(get(parseInt(id)));
	}, [id]);

	if (!order || progressId !== null) return <Preloader />;

	return (
		<>
			<DeliveryFilter
				orderNum={order.number!}
				size={size}
				color={color}
				model={model}
				setColor={setColor}
				setSize={setSize}
				setModel={setModel}
			/>
			<DeliveryList size={size} color={color} model={model} />
		</>
	);
};

export default DeliveryPage;
