import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { authUserSelector } from 'ducks/auth';
import UsersPage from 'components/AdminPage/UsersPage/UsersPage';
import TicketsPage from 'components/AdminPage/TicketsPage/TicketsPage';
import { UserList } from 'components/AdminPage/UserList/UserList';
import { CodeList } from 'components/AdminPage/CodeList/CodeList';
import { ReportPage } from 'components/AdminPage/ReportPage/ReportPage';

const AdminPage: React.FC = () => {
	const user = useSelector(authUserSelector, shallowEqual);

	if (!user?.isAdmin) return <Redirect to="/auth" />;

	return (
		<Switch>
			<Route exact path="/admin/users" component={UsersPage} />
			<Route path="/admin/tickets" component={TicketsPage} />
			<Route path="/admin/userlist/:id" component={UserList} />
			<Route path="/admin/codelist/:userId/:id" component={CodeList} />
			<Route path="/admin/report" component={ReportPage} />
		</Switch>
	);
};

export default AdminPage;
