import React from 'react';

import OrderDto from 'dto/OrderDto';
import OrderItem from 'components/ListPage/OrderItem';
import AddTicket from 'shared/AddTicket/AddTicket';

export interface OrdersListProps {
	list: OrderDto[];
}

const OrdersList: React.FC<OrdersListProps> = ({ list }) => {
	return (
		<div className="table-overlay">
			<table className="list-table">
				<thead>
					<tr>
						<th>Order Number</th>
						<th>Currency</th>
						<th>Delivery Number</th>
						<th>Quantity</th>
						<th>Total Sum </th>
						<th>Marking</th>
						<th>Comments</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{list.map(item => (
						<OrderItem order={item} key={item.id} />
					))}
				</tbody>
			</table>
			<AddTicket />
		</div>
	);
};

export default OrdersList;
