import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './user-page.scss';
import { dataUsersListSelector, getUsers } from 'ducks/data/users';
import UsersSearch from 'components/AdminPage/UsersPage/UsersSearch';
import UserItem from 'components/AdminPage/UsersPage/UserItem';

const UsersPage: React.FC = () => {
	const dispatch = useDispatch();
	const list = useSelector(dataUsersListSelector, shallowEqual);

	useEffect(() => {
		dispatch(getUsers());
	}, []);

	const doSearch = () => {
		dispatch(getUsers());
	};

	return (
		<div className="user-page">
			<UsersSearch onSearch={doSearch} />
			<div className="table-overlay">
				<table className="list-table">
					<thead>
						<tr>
							<th>id</th>
							<th>Active</th>
							<th>Email</th>
							<th>Название</th>
							<th style={{ minWidth: '100px' }}>Пэшка</th>
							<th>ПР</th>
							<th>Компания</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{list.map(item => (
							<UserItem user={item} key={item.id} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default UsersPage;
