import 'reflect-metadata';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './assets/svgSprite/svgInsert';
import './scss/index.scss';
import App from './components/App';
import initStore from 'api/init';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const store = initStore();

render(
	<Provider store={store}>
		<BrowserRouter>
			{/*<ScrollToTop>*/}
			<Switch>
				<Route path="/buyerpro" component={App} />
				<Route path={['/', '/auth']} component={App} />
			</Switch>
			{/*</ScrollToTop>*/}
		</BrowserRouter>
	</Provider>,
	document.getElementById('app'),
);
