import React from 'react';
import Svg from 'common/Svg';

const VerificationStatusWait: React.FC = () => {
	return (
		<div className="alert alert--ready">
			<div className="alert__row">
				<div className="alert__icn">
					<Svg name="exclamation-circle" />
				</div>
				<div className="alert__icn">
					<Svg name="exclamation-circle" />
				</div>
				<div className="alert__icn">
					<Svg name="x-circle" />
				</div>
			</div>
			<div className="alert__txt">Ready to work</div>
		</div>
	);
};

export default VerificationStatusWait;
