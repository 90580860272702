import React from 'react';

import './main-sec.scss';
import RemoveFilter from 'components/RemovePage/RemoveFilter/RemoveFilter';
import RemoveList from 'components/RemovePage/RemoveList';

const RemovePage: React.FC = () => {
	return (
		<>
			<section className="main-sec">
				<RemoveFilter />
			</section>
			<RemoveList />
		</>
	);
};

export default RemovePage;
