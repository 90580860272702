import { combineReducers } from 'redux';

import ticket, { EditTicketState, initialState as EditTicketInitialState } from './ticket';
import verification, { EditVerificationState, initialState as EditVerificationInitialState } from './verification';
import removed, { EditRemovedState, initialState as EditRemovedInitialState } from './removed';

export interface EditState {
	ticket: EditTicketState;
	verification: EditVerificationState;
	removed: EditRemovedState;
}
export const editSelector = (state: { edit: EditState }): EditState => state.edit;

export const initialState: EditState = {
	ticket: EditTicketInitialState,
	verification: EditVerificationInitialState,
	removed: EditRemovedInitialState,
};

export default combineReducers<EditState>({
	ticket,
	verification,
	removed,
});
