import React from 'react';
import { Switch, Redirect, useLocation, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { authIsAuthorizedSelector } from 'ducks/auth';
import AuthPage from 'components/AuthPage/AuthPage';
import MainPage from 'components/MainPage';
import { uiPrloadersPreloaderSelector } from 'ducks/ui/preloaders';
import Preloader from 'common/Preloader/Preloader';

const App: React.FC = () => {
	const { pathname } = useLocation();
	const isPreloader = useSelector(uiPrloadersPreloaderSelector);
	const isAuthorized = useSelector(authIsAuthorizedSelector, shallowEqual);

	if (isPreloader)
		return (
			<div className="main__preloader">
				<Preloader />
			</div>
		);

	if (!isAuthorized && pathname.indexOf('/auth') < 0) return <Redirect to="/auth" />;

	return (
		<Switch>
			<Route path="/auth" component={AuthPage} />
			<Route path="/" component={MainPage} />
		</Switch>
	);
};

export default App;
