import { createSelector } from 'reselect';
import { AnyAction } from 'redux';

import api from '../../api';
import { AppDispatch, AppState } from '../../redux/reducer';
import { getVerifications } from 'ducks/data/verification';

/**
 * Constants
 **/
export const moduleName = 'edit/verification';

export const CLEAR = `${moduleName}/CLEAR`;
export const SET_CODE = `${moduleName}/SET_CODE`;
export const SET_STATUS = `${moduleName}/SET_STATUS`;

export type VerificationStatusType = 'wait' | 'success' | 'error' | 'warning' | 'ean';

export interface EditVerificationState {
	code: string;
	status: VerificationStatusType;
}

export const initialState: EditVerificationState = {
	code: '',
	status: 'wait',
};

export interface EditVerificationAction extends AnyAction {
	readonly type: string;
	readonly payload?: {
		code?: string;
		status?: VerificationStatusType;
	};
}

export default (state = initialState, action: EditVerificationAction) => {
	const { type, payload } = action;

	switch (type) {
		case CLEAR:
			return { ...initialState };
		case SET_CODE:
			return { ...state, code: payload?.code || '' };
		case SET_STATUS:
			return { ...state, status: payload?.status || 'wait' };
		default:
			return { ...state };
	}
};

export const clear = () => ({
	type: CLEAR,
});

export const setCode = (code: string) => ({
	type: SET_CODE,
	payload: { code },
});

export const setStatus = (status: VerificationStatusType) => ({
	type: SET_STATUS,
	payload: { status },
});

export const checkCode = (userId?: number) => (dispatch: AppDispatch, getState: () => AppState) => {
	const { code } = getState().edit.verification;
	dispatch(setStatus('wait'));
	if (code.length === 13) {
		dispatch(setStatus('ean'));
		return;
	}
	dispatch(getVerifications(code));
	api.verification.check(code, userId).then(res => {
		if (res.success) {
			dispatch(setStatus('success'));
		} else if (res.error?.code === 404) {
			dispatch(setStatus('error'));
		} else if (res.error?.code === 405) {
			dispatch(setStatus('warning'));
		}
	});
};

const editSelector = (state: AppState) => state.edit;
export const editVerificationSelector = createSelector(editSelector, edit => edit.verification);
export const editVerificationCodeSelector = createSelector(editVerificationSelector, verification => verification.code);
export const editVerificationStatusSelector = createSelector(
	editVerificationSelector,
	verification => verification.status,
);
