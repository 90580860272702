import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './main.scss';
import AdminPage from 'components/AdminPage/AdminPage';
import Header from 'shared/Header/Header';
import Sidebar from 'shared/Sidebar/Sidebar';
import ListPage from 'components/ListPage/ListPage';
import IndexPage from 'components/IndexPage';
import VerificationPage from 'components/VerificationPage/VerificationPage';
import ProfilePage from 'components/ProfilePage/ProfilePage';
import DeliveryPage from 'components/DeliveryPage/DeliveryPage';
import RemovePage from 'components/RemovePage/RemovePage';

const MainPage: React.FC = () => {
	return (
		<div className="wrapper">
			<Header />
			<Sidebar />
			<main className="main">
				<Switch>
					<Route exact path="/" component={IndexPage} />
					<Route exact path="/list" component={ListPage} />
					<Route exact path="/list/item/:id" component={DeliveryPage} />
					<Route path="/admin" component={AdminPage} />
					<Route path="/verification" component={VerificationPage} />
					<Route path="/remove" component={RemovePage} />
					<Route path="/profile" component={ProfilePage} />
				</Switch>
			</main>
		</div>
	);
};

export default MainPage;
