import { createEffect, createStore } from 'effector';

import api from 'api/index';
import OrderDto from 'dto/OrderDto';

export const getUserOrders = createEffect<number, OrderDto[]>(async userId => {
	return await api.admin.orders(userId).then(res => {
		if (res.success && res.data) return res.data as OrderDto[];
		throw Error(res.error?.message);
	});
});

export const $listLoading = createStore<boolean>(false)
	.on(getUserOrders, () => true)
	.on(getUserOrders.finally, () => false);

export const $list = createStore<OrderDto[]>([]).on(getUserOrders.doneData, (__, data) => data);
