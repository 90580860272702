import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import DeliveryItem from 'components/DeliveryPage/DeliveryItem';
import { dataInfoListSelector, dataInfoProgressSelector } from 'ducks/data/info';
import Preloader from 'common/Preloader/Preloader';

export interface DeliveryListProps {
	size: string | null;
	color: string | null;
	model: string | null;
}

const DeliveryList: React.FC<DeliveryListProps> = ({ size, color, model }) => {
	const list = useSelector(dataInfoListSelector, shallowEqual);
	const progress = useSelector(dataInfoProgressSelector, shallowEqual);

	if (progress) return <Preloader />;

	let filteredList = list;
	if (size) {
		filteredList = filteredList.filter(item => item.size === size);
	}
	if (color) {
		filteredList = filteredList.filter(item => item.color === color);
	}
	if (model) {
		filteredList = filteredList.filter(item => item.model === model);
	}

	return (
		<div className="table-overlay">
			<table className="list-table">
				<thead>
					<tr>
						<th>Model</th>
						<th>Name (eng)</th>
						<th>Item ID (RecId)</th>
						<th>Color ID</th>
						<th>Size ID</th>
						<th>Quantity</th>
						<th>Gtin</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{filteredList.map(item => (
						<DeliveryItem item={item} key={item.id} />
					))}
				</tbody>
			</table>
		</div>
	);
};

export default DeliveryList;
