import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import './header.scss';
import Svg from 'common/Svg';
import { authUserSelector } from 'ducks/auth';
import { getAvatar, getName } from 'utils/userHelper';

const Header: React.FC = () => {
	const user = useSelector(authUserSelector, shallowEqual);

	return (
		<header className="header">
			<nav className="header-nav">
				<ul>
					<li>
						<NavLink to="/list" className="header-nav-item">
							<span className="header-nav-item__icn">
								<Svg name="text-document" />
							</span>
							<span className="header-nav-item__txt">List marking</span>
						</NavLink>
					</li>
					<li>
						<NavLink to="/verification" className="header-nav-item">
							<span className="header-nav-item__icn">
								<Svg name="scan" />
							</span>
							<span className="header-nav-item__txt">Verification</span>
						</NavLink>
					</li>
					<li>
						<a href="/upload/PartnershipInstruction.docx" className="header-nav-item">
							<span className="header-nav-item__icn">
								<Svg name="info" />
							</span>
							<span className="header-nav-item__txt">Instruction</span>
						</a>
					</li>
					{/*<li>*/}
					{/*	<NavLink to="/reports" className="header-nav-item">*/}
					{/*		<span className="header-nav-item__icn">*/}
					{/*			<Svg name="pie-chart-alt" />*/}
					{/*		</span>*/}
					{/*		<span className="header-nav-item__txt">Reports</span>*/}
					{/*	</NavLink>*/}
					{/*</li>*/}
					<li>
						<NavLink to="/remove" className="header-nav-item">
							<span className="header-nav-item__icn">
								<Svg name="delete_item" />
							</span>
							<span className="header-nav-item__txt">Delete item from order</span>
						</NavLink>
					</li>
					{user!.isAdmin && (
						<li>
							<NavLink to="/admin/tickets" className="header-nav-item">
								<span className="header-nav-item__icn">
									<Svg name="help" />
								</span>
								<span className="header-nav-item__txt">Tickets</span>
							</NavLink>
						</li>
					)}
				</ul>
			</nav>
			<Link to="/profile" className="profile-btn">
				<div className="profile-btn__ava">
					<img src={getAvatar(user!)} alt={getName(user!)} />
				</div>
				<div className="profile-btn__name">{getName(user!)}</div>
			</Link>
		</header>
	);
};

export default Header;
