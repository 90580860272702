import React from 'react';
import TicketDto, { TicketStatus } from 'dto/TicketDto';
import Svg from 'common/Svg';
import moment from 'moment';
import { urlBackend } from '../../../config';

export interface HelpDeskItemProps {
	ticket: TicketDto;
}

const getStatus = (status: TicketStatus) => {
	switch (status) {
		case 'done':
			return { color: 'green', text: 'Solved' };
		case 'open':
			return { color: 'blue', text: 'Open' };
		case 'progress':
			return { color: 'orange', text: 'In Progress' };
	}
};

const HelpDeskItem: React.FC<HelpDeskItemProps> = ({ ticket }) => {
	const { topic, createdAt, attach, status } = ticket;
	const showStatus = getStatus(status!);
	return (
		<tr>
			<td>{topic}</td>
			<td>{moment(createdAt).format('DD.MM.YYYY')}</td>
			<td>
				{attach && attach.path && (
					<a href={`${urlBackend}/upload/${attach.path}`} className="doc">
						<Svg name="paper" />
					</a>
				)}
			</td>
			<td>
				<span className={showStatus.color}>{showStatus.text}</span>
			</td>
			<td>
				<div className="styled-select styled-select_short js-styled-select">
					<select name="categorys">
						<option>Finished</option>
						<option value="Finished">Finished</option>
						<option value="Start">Start</option>
					</select>
				</div>
			</td>
		</tr>
	);
};

export default HelpDeskItem;
